import { ROUTER_AUTH } from '@App/Auth/configs/const'
import { ROUTER_MY_ACCOUNT } from '@App/Studio/Modules/MyAccount/configs/const'
import DropDownAction from '@Core/Components/Dropdown/DropDownAction'
import { getS3Url } from '@Core/Helpers/Url'
import { useCoreAuth, useCoreAuthActions } from '@Core/Provider/CoreAuthProvider'
import { ExitToAppOutlined } from '@mui/icons-material'
import { Avatar, Button, Skeleton, Tooltip, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const UserHeader = () => {
	const { user, isLoading } = useCoreAuth()
	const { logout } = useCoreAuthActions()

	const { t } = useTranslation()
	const navigate = useNavigate()

	// console.log('🚀 ~ User ~ user:', user)

	const userActions = useMemo(() => {
		const data = [
			user?.is_allow_crm
				? {
						label: <span>CRM nội bộ</span>,
						action: () => {
							window.location.href = '/crm/marketing/short-links'
						},
						divider: true
				  }
				: null,
			{
				label: <span>{t('Profile')}</span>,
				action: () => navigate(ROUTER_MY_ACCOUNT.profile),
				divider: true
			},
			{
				label: (
					<div className="flex gap-2">
						<ExitToAppOutlined className="text-mainOrange" />
						<Typography variant="M14">{t('Log out')}</Typography>
					</div>
				),
				action: () => logout()
			}
		].filter(Boolean)
		return data
	}, [user])
	return isLoading ? (
		<div className="flex items-center gap-2 px-2 bg-gray-100 rounded-md">
			<Skeleton variant="circular" className="w-[40px] h-[40px]" />
			<Skeleton variant="text" className="w-[120px] h-[32px]" />
		</div>
	) : (
		<div className="flex items-center gap-4">
			{!user?.id ? (
				<>
					<Typography>{t('You are not logged in')}</Typography>
					<Button onClick={() => navigate(ROUTER_AUTH.login)} variant="main-gradient">
						{t('Login')}
					</Button>{' '}
				</>
			) : (
				<DropDownAction
					options={userActions}
					triggerElement={
						<div className="flex items-center gap-2 p-2 rounded-md hover:bg-gray-100">
							<Tooltip title={t('Tài khoản của bạn')}>
								<Avatar
									src={getS3Url(user?.info?.avatar_url)}
									alt={user?.displayName}
									className="border"
								/>
							</Tooltip>
							{/* <Typography variant="M16" className="hidden sm:inline-flex">
								{user?.displayName}
							</Typography> */}
						</div>
					}
				/>
			)}
		</div>
	)
}

// UserHeader.defaultProps = {}

// UserHeader.propTypes = {}

export default React.memo(UserHeader)
