import { Computer, DesktopMac, Smartphone, Tablet } from '@mui/icons-material'

export const APP_NAME = {
	studio: 'studio',
	quiz: 'quiz',
	auth: 'auth'
}

export const USER_INFO_TYPE = {
	student: 'STUDENT',
	teacher: 'TEACHER',
	org: 'ORGANIZATION'
}

export const QUESTION_TYPE = {
	single: 'SINGLE',
	mulitple: 'MULTIPLE',
	boolean: 'BOOLEAN',
	fill_word: 'FILL_WORD',
	word_matching: 'WORD_MATCHING',
	reading: 'READING',
	essay: 'ESSAY'
}

export const QUESTION_LEVEL = {
	easy: 'EASY',
	medium: 'MEDIUM',
	hard: 'HARD'
}

export const EXAM_MODE_STUDY = 'STUDY_TEST'
export const EXAM_MODE_TEST = 'TEST'
export const EXAM_MODE_ROOM = 'EXAM_ROOM'
export const EXAM_MODE_EXERCISE = 'EXAM_EXERCISE'

export const PLAN_FEATURE = {
	exam: 'exam', // Số đề thi
	question: 'question', // Số câu hỏi
	exam_play: 'exam-play', // Số lượt thi
	admin: 'admin', // Số quản trị viên
	create_question: 'create-question' // Soạn câu hỏi nâng cao
}

export const EXAM_STAGE = {
	status: {
		upcoming: 'upcoming',
		going_on: 'going_on',
		pause: 'pause',
		finished: 'finished'
	}
}

export const EXAM_ROOM = {
	opening_time_type: {
		default: 'default',
		custom: 'custom'
	},
	allow_duration: [5, 10, 15, 20, 30, 45, 50, 60, 75, 90, 120],
	status_access: {
		link: 'LINK',
		link_password: 'LINK_PASSWORD',
		classroom: 'CLASSROOM'
	},
	status: {
		upcoming: 'upcoming',
		going_on: 'going_on',
		pause: 'pause',
		finished: 'finished'
	},
	type: {
		default: 'default',
		exercise: 'exercise'
	}
}

export const PLAN_TYPE = {
	personal: 'PERSONAL',
	workspace: 'WORKSPACE'
}

export const DEVICE_ICON = {
	desktop: <Computer />,
	phone: <Smartphone />,
	tablet: <Tablet />
	// robot : <Rob
}

// case START_EXAM = 'START_EXAM';
//     case SUBMIT_EXAM = 'SUBMIT_EXAM';
//     case EXIT_EXAM_SCREEN = 'EXIT_EXAM_SCREEN';
//     case RETURN_EXAM_SCREEN = 'RETURN_EXAM_SCREEN';
//     case START_QUESTION = 'START_QUESTION';
//     case CHOOSE_ANSWER_QUESTION = 'CHOOSE_ANSWER_QUESTION';
//     case EXIT_FULSCREEN = 'EXIT_FULSCREEN';
//     case RETURN_FULLSCREEN = 'RETURN_FULLSCREEN';
export const EXAM_ROOM_LOG = {
	type: {
		EXIT_FULSCREEN: 'EXIT_FULSCREEN',
		RETURN_FULLSCREEN: 'RETURN_FULLSCREEN',
		CHOOSE_ANSWER_QUESTION: 'CHOOSE_ANSWER_QUESTION',
		EXIT_EXAM_SCREEN: 'EXIT_EXAM_SCREEN',
		RETURN_EXAM_SCREEN: 'RETURN_EXAM_SCREEN'
	}
}

export const RANK_TYPE = {
	creator: 'TOP_CREATOR',
	superstar: 'TOP_SUPERSTAR'
}
