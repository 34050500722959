import { ArrowBack } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, Typography } from '@mui/material'
import Image from 'mui-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaAngleLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const AuthLayout = ({ img = '/assets/images/auth/login.png', children, showBtnBack, backUrl }) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const handleGoback = () => {
		if (backUrl) {
			navigate(backUrl)
			return
		}
		const hasPreviousPage = location.key !== 'default'
		if (hasPreviousPage) {
			navigate(-1)
		}
	}
	return (
		<div className="relative flex flex-col h-screen bg-white md:flex-row">
			{showBtnBack && (
				<Button
					color="inherit"
					className="absolute flex items-center space-x-3 top-4 z-99 md:hidden text-[1rem] mx-2"
					startIcon={<ArrowBack />}
					onClick={handleGoback}
				>
					{t('Trở về')}
				</Button>
			)}

			<div className="w-full md:w-3/5">
				<Image src={img} className="h-[500px] md:h-full" />
			</div>
			<div className="absolute md:relative top-[40%] md:top-0 flex items-center justify-center w-full md:w-2/5 bg-white">
				<div className="w-full md:w-3/4 max-w-[500px] md:h-max ">{children}</div>
			</div>
		</div>
	)
}

// AuthLayout.defaultProps = {}

// AuthLayout.propTypes = {}

export default React.memo(AuthLayout)
