import React, { useEffect, useMemo, useState } from 'react'
import { useCoreAuth } from '../CoreAuthProvider'
import planService from '@App/Studio/services/planService'
import { get } from 'lodash'
import { useRequest } from 'ahooks'
import { warningMsg } from '@Core/Helpers/Message'
import { useTranslation } from 'react-i18next'
import { useFeatureRemaining } from './hooks/useFeatureRemaining'
import { PLAN_FEATURE } from '@Core/Components/const'
import AlertUpgradePlan from '@Core/Components/Common/Plan/AlertUpgradePlan'
import { useCorePlanContext } from '.'
// import PropTypes from 'prop-types'

const featureObject = { loading: false, remaining: 0, canUseFeature: () => {}, fetchRemaining: () => {} }

const CorePlanFeatureContext = React.createContext({
	planUpgradingMsg: () => {},
	featureCreateQuestion: featureObject,
	featureAdmin: featureObject,
	featureExam: featureObject,
	featureQuestion: featureObject,
	canUseFeatureExamQuestion: () => {},
	renderAlertFeatureExamQuestion: () => {},
	loadingFeatureExamQuestion: true
})

export const useCorePlanFeatureContext = () => React.useContext(CorePlanFeatureContext)

const CorePlanFeatureProvider = props => {
	const { t } = useTranslation()

	const { isAuthenticated } = useCoreAuth()
	const { currentPlan } = useCorePlanContext()

	const featureExam = useFeatureRemaining({ feature: PLAN_FEATURE.exam })
	const featureQuestion = useFeatureRemaining({ feature: PLAN_FEATURE.question })
	const featureCreateQuestion = useFeatureRemaining({ feature: PLAN_FEATURE.create_question })
	const featureAdmin = useFeatureRemaining({ feature: PLAN_FEATURE.admin })

	useEffect(() => {
		// console.log('============= currentPlan', currentPlan)
		if (currentPlan?.id) {
			featureExam.fetchRemaining()
			featureQuestion.fetchRemaining()
		}
	}, [JSON.stringify(currentPlan)])

	const loadingFeatureExamQuestion = featureExam.loading || featureQuestion.loading

	const canUseFeatureExamQuestion = () => {
		return featureExam.canUseFeature() && featureQuestion.canUseFeature()
	}

	const renderAlertFeatureExamQuestion = () => {
		if (!featureExam.canUseFeature()) {
			return <AlertUpgradePlan className="mb-2" feature={PLAN_FEATURE.exam} showButton />
		}
		if (!featureQuestion.canUseFeature()) {
			return <AlertUpgradePlan className="mb-2" feature={PLAN_FEATURE.question} showButton />
		}
	}

	const planUpgradingMsg = () => {
		warningMsg(t('Vui lòng đăng ký hoặc nâng cấp gói dịch vụ'))
	}

	const context = {
		loadingFeatureExamQuestion,
		renderAlertFeatureExamQuestion,
		canUseFeatureExamQuestion,
		featureCreateQuestion,
		featureAdmin,
		featureExam,
		featureQuestion,
		planUpgradingMsg
	}
	return <CorePlanFeatureContext.Provider value={context}>{props.children}</CorePlanFeatureContext.Provider>
}

// CorePlanFeatureProvider.defaultProps = {}

// CorePlanFeatureProvider.propTypes = {}

export default React.memo(CorePlanFeatureProvider)
