import Axios from 'axios'
import { pickBy } from 'lodash'
import Qs from 'qs'
import * as Sentry from '@sentry/react'
import { getCookieMarketing } from '@Core/Helpers/System'
import Cookies from 'js-cookie'
import { getCampaignSale } from '@Core/Helpers/Session'
export const createInstance = (baseUrl = null, middleware = () => {}) => {
	const options = {
		baseURL: baseUrl,
		timeout: 30000,
		headers: {
			'X-Requested-With': 'XMLHttpRequest'
			// 'Access-Control-Allow-Origin': '*'
		},
		withCredentials: true,
		withXSRFToken: true,
		paramsSerializer(params) {
			params = pickBy(params, val => {
				return val !== null && val !== '' && val !== 'undefined'
			})
			return Qs.stringify(params)
		}
	}

	const instance = Axios.create(options)

	instance.interceptors.request.use(
		async requestConfig => {
			await Promise.all(middleware(requestConfig))
			// const marketing = getCookieMarketing()

			requestConfig.headers.marketing = Cookies.get('marketing')
			const campaign_sale = getCampaignSale()
			// console.log('============= campaign_sale', campaign_sale)
			if (campaign_sale) {
				requestConfig.headers.campaignsale = campaign_sale
			}

			return requestConfig
		},
		requestError => {
			// console.log(requestError)
			return Promise.reject(requestError)
		}
	)

	// Add a response interceptor
	instance.interceptors.response.use(
		response => {
			// console.log('============= response', response)
			const { data } = response
			if (data?.status === 'error') {
				return Promise.reject(data)
			}
			if (data.errors) {
				// hideLoadingPage()
				return Promise.reject(data)
			}
			if (data.error_msg) {
				// hideLoadingPage()
				return Promise.reject(data)
			}
			if (data?.data && !data?.total && !data?.current_page && !data?.remaining) {
				return data?.data
			}
			return data
		},
		error => {
			// hideLoadingPage()

			const { data = {} } = error?.response
			if (error?.response) {
				Sentry.captureException(error?.response)
			}

			if (data?.errors) {
				return Promise.reject(data)
			}
			if (data?.message) {
				return Promise.reject(data?.message)
			}
			return Promise.reject(error)
		}
	)
	return instance
}
