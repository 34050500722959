import { userRole } from '@App/Studio/app-config/const'
import { lazy } from 'react'
import { ROUTER_PERSONAL_DASHBOARD } from './const'
import { Navigate } from 'react-router-dom'

const OverviewLazy = lazy(() => import('../pages/Overview'))
const ListExamHomework = lazy(() => import('../pages/Overview/components/ExamHomeworkDashboard/ListExamHomework/index'))
const ExamListPageLazy = lazy(() => import('../pages/ExamList/ExamRecent/index'))
const ListFavoriteExamPageLazy = lazy(() => import('../pages/ExamList/ExamFavorite'))

export const personalDashboardRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_PERSONAL_DASHBOARD.root,
			exact: true,
			element: <Navigate to={ROUTER_PERSONAL_DASHBOARD.dashboard} />
		},
		{
			path: ROUTER_PERSONAL_DASHBOARD.dashboard,
			exact: true,
			element: <OverviewLazy />
		},
		{
			path: ROUTER_PERSONAL_DASHBOARD.exams_homework,
			exact: true,
			element: <ListExamHomework />
		},
		{
			path: ROUTER_PERSONAL_DASHBOARD.exams,
			element: <ExamListPageLazy />
		},
		{
			path: ROUTER_PERSONAL_DASHBOARD.favorite_exams,
			element: <ListFavoriteExamPageLazy />
		}
	]
}
