import { CoreInput } from '@Core/Components/Input'
import { Alert, LoadingButton } from '@mui/lab'
import { Button, Divider, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCoreAuth } from '../CoreAuthProvider'
import { Facebook, Mail, Phone } from '@mui/icons-material'
import CoreRadioGroup from '@Core/Components/Input/CoreRadioGroup'
import { yupResolver } from '@hookform/resolvers/yup'
import Yup from '@Core/Helpers/Yup'
import { errorMsg, successMsg } from '@Core/Helpers/Message'
import workspaceService from '@App/Studio/services/workspaceService'
import { useCoreContactContext } from '.'
// import PropTypes from 'prop-types'

const ContactForm = props => {
	const { t } = useTranslation()
	const { user } = useCoreAuth()
	const { handleClose } = useCoreContactContext()
	const {
		control,
		formState: { isDirty, isSubmitting, isSubmitSuccessful },
		handleSubmit
	} = useForm({
		mode: 'onTouched',
		defaultValues: {
			name: user?.info?.fullname ?? '',
			phone: user?.mobile ?? '',
			email: user?.email ?? '',
			message: '',
			type: 'bug'
		},
		resolver: yupResolver(
			Yup.object({
				message: Yup.string().required()
			})
		)
	})

	const typeOptions = useMemo(() => {
		return [
			{
				label: t('Báo lỗi'),
				value: 'bug'
			},
			{
				label: t('Đề xuất tính năng'),
				value: 'recommend_feature'
			},
			{
				label: t('Mong muốn tư vấn'),
				value: 'default'
			}
		]
	}, [t])

	const onSubmit = handleSubmit(async data => {
		try {
			await workspaceService.sendContact(data)
			successMsg(t('Gửi liên hệ thành công'))
			handleClose()
		} catch (e) {
			errorMsg(e)
		}
	})

	return (
		<div>
			<div className="flex flex-col gap-3">
				<Typography variant="M16">{t('Cảm ơn bạn đã quan tâm và sử dụng ứng dụng. ')}</Typography>
				{/* <Typography variant="M16">{t('Bạn có thể liên hệ với chúng tôi bằng cách:')}</Typography> */}
				<div className="flex gap-5">
					{/* <Button color="success" startIcon={<Phone />}>
						<a href="tel:0394149966">0394149966</a>
					</Button> */}
					{/* <Button color="error" startIcon={<Mail />}>
						<a href="mailto:cskh@eduquiz.vn">cskh@eduquiz.vn</a>
					</Button> */}
					<Button startIcon={<Facebook />}>
						<a href="https://www.facebook.com/eduquiz.vn" target="_blank" rel="noreferrer">
							Fanpage
						</a>
					</Button>
				</div>
				<Alert>Hãy liên hệ với chúng tôi qua Fanpage để được admin hỗ trợ nhanh nhất</Alert>
			</div>
			{/* <Divider className="my-5">{t('Hoặc gửi liên hệ')}</Divider>
			<form onSubmit={onSubmit} className="flex flex-col mt-3 gap-formItem">
				<CoreRadioGroup
					row
					control={control}
					name="type"
					options={typeOptions}
					// legendLabel={t('Chọn loại liên')}
				/>
				<CoreInput
					multiline
					control={control}
					name="message"
					label={t('Mô tả')}
					size="small"
					placeholder={t('Nhập mô tả thông tin')}
					required
				/>

				<div>
					<LoadingButton
						type="submit"
						variant="contained"
						gradient="primary"
						fullWidth
						disabled={!isDirty}
						loading={isSubmitting}
					>
						{t('Xác nhận gửi thông tin')}
					</LoadingButton>
				</div>
			</form> */}
		</div>
	)
}

// ContactForm.defaultProps = {}

// ContactForm.propTypes = {}

export default React.memo(ContactForm)
