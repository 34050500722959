import uploadService from '@App/Studio/services/admin/uploadService'
import workspaceService from '@App/Studio/services/workspaceService'
import AppCard from '@Core/Components/Card/AppCard'
import { CoreInput } from '@Core/Components/Input'
import CoreRadioGroup from '@Core/Components/Input/CoreRadioGroup'
import CoreSelectAvatar from '@Core/Components/Input/CoreSelectAvatar'
import CoreUploadFile from '@Core/Components/Input/CoreUploadFile'
import { errorMsg, handleErrorFieldBackend } from '@Core/Helpers/Message'
import { LOCAL_STORAGE, getDataSession } from '@Core/Helpers/Session'
import Yup from '@Core/Helpers/Yup'
import { useCoreWorkspaceContext } from '@Core/Provider/CoreWorkspaceProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCoreOnboardingContext } from '..'
// import PropTypes from 'prop-types'

const CreateWorkspace = props => {
	const { t } = useTranslation()
	const { getCurrentWorkspace } = useCoreWorkspaceContext()
	const { handleCloseOnboarding, setStep } = useCoreOnboardingContext()
	const methodForm = useForm({
		mode: 'onTouched',
		defaultValues: {
			// avatar: '',
			name: '',
			type: 'PERSONAL'
			// country: ''
		},
		resolver: yupResolver(
			Yup.object({
				// avatar: Yup.string().trim().required(),
				name: Yup.string().required().min(2).max(255)
				// country: Yup.string().required()
			})
		)
	})
	const {
		control,
		formState: { isDirty, isSubmitting }
	} = methodForm

	const onSubmit = methodForm.handleSubmit(async data => {
		try {
			await workspaceService.createWorkspace(data)
			await getCurrentWorkspace()
			handleCloseOnboarding()

			// navigate(ROUTER_WORKSPACE_PLAN.list_plan)
		} catch (e) {
			errorMsg(e)
			handleErrorFieldBackend(e, methodForm.setError)
		}
	})

	return (
		<div className="max-w-md mx-auto">
			<FormProvider {...methodForm}>
				<div className="p-4 mb-24">
					<form onSubmit={onSubmit} className="flex flex-col w-full gap-formItem">
						<Typography component="h3" variant="M18" className="text-center">
							{t('Tạo kênh đề thi')}
						</Typography>
						{/* <div className="flex items-center gap-5">
							<CoreUploadFile
								name="avatar"
								control={control}
								uploadService={uploadService.uploadImage}
								fielType="image"
								type="AVATAR"
								label={t('Ảnh đại diện')}
								imageClassName="w-68"
								// helperText={t('image validator')}
							/>
						</div> */}
						<CoreSelectAvatar name="avatar" control={control} className="mt-8" />
						<CoreInput
							control={control}
							name="name"
							label={t('Tên kênh đề thi')}
							placeholder={t('Nhập tên kênh đề thi')}
							size="small"
							required
						/>
						<div className="grid grid-cols-2 gap-formItem md:mb-2">
							{/* <Button
								variant="contained"
								disableElevation
								color="inherit"
								onClick={() => setStep('about-us')}
							>
								{t('Trở về')}
							</Button> */}
							<LoadingButton
								variant="main-gradient"
								type="submit"
								loading={isSubmitting}
								disabled={!isDirty}
							>
								{t('Xác nhận')}
							</LoadingButton>
						</div>
					</form>
				</div>
			</FormProvider>
		</div>
	)
}

// CreateWorkspace.defaultProps = {}

// CreateWorkspace.propTypes = {}

export default React.memo(CreateWorkspace)
