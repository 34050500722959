import userExamService from '@App/Studio/services/user/userExamService'
import { EXAM_ROOM } from '@Core/Components/const'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import { useRequest } from 'ahooks'
import React, { useMemo } from 'react'
// import PropTypes from 'prop-types'

const PersonalContext = React.createContext({
	room_not_compelete: {
		total_room: 0,
		total_exercise: 0
	}
})

export const useCorePersonalContext = () => React.useContext(PersonalContext)

const CorePersonalProvider = props => {
	const { isAuthenticated } = useCoreAuth()
	const { data: statisExamRoom = [], loading } = useRequest(userExamService.getStatisExamRoom, {
		ready: isAuthenticated,
		cacheKey: 'statisExamRoom',
		cacheTime: 1000 * 60 * 5,
		staleTime: 1000 * 60 * 5
	})

	const total_room = useMemo(() => {
		const room = statisExamRoom?.find(i => i?.type === EXAM_ROOM.type.default)
		return room?.total ?? 0
	}, [loading])

	const total_exercise = useMemo(() => {
		const room = statisExamRoom?.find(i => i?.type === EXAM_ROOM.type.exercise)
		return room?.total ?? 0
	}, [loading])

	const context = { room_not_compelete: { total_room, total_exercise } }

	return <PersonalContext.Provider value={context}>{props.children}</PersonalContext.Provider>
}

// CorePersonalProvider.defaultProps = {}

// CorePersonalProvider.propTypes = {}

export default React.memo(CorePersonalProvider)
