import { Box, Typography } from '@mui/material'
import axios from 'axios'
import Image from 'mui-image'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

const ImageQRcode = ({ url, showLabel = false, label, width, height }) => {
	const { t } = useTranslation()
	const [data, setData] = useState()
	const src = useMemo(() => {
		return `https://qrcode.eduquiz.io.vn/api/img?content=${url}`
	}, [url])

	useEffect(() => {
		axios
			.get(src, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'X-Requested-With': 'XMLHttpRequest'
				},
				withCredentials: true,
				withXSRFToken: true
			})
			.then(res => {
				// console.log('============= res', res)
				setData(res.data)
			})
		// fetch(src, { withCredentials: true, withXSRFToken: true }).then(res => console.log('============= res', res))
	}, [url])

	if (!data) return null

	return (
		<Box
			className="flex flex-col gap-2"
			sx={{
				'& svg': {
					width,
					height
				}
			}}
		>
			{showLabel && <Typography variant="SB14">{t(label ?? 'Quét mã QR để vào thi')}</Typography>}

			<div dangerouslySetInnerHTML={{ __html: data }} />
		</Box>
	)
}

// ImageQRcode.defaultProps = {}

// ImageQRcode.propTypes = {}

export default React.memo(ImageQRcode)
