/* eslint-disable jsx-a11y/control-has-associated-label */
import mktService from '@App/Quiz/services/mktService'
import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useBoolean, useRequest } from 'ahooks'
import Cookies from 'js-cookie'
import { find } from 'lodash'
import Image from 'mui-image'
import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'

const CoreBannerMarketingContext = React.createContext({
	getBannerPosition: slug => {}
})

export const useCoreBannerMarketingContext = () => React.useContext(CoreBannerMarketingContext)

const CoreBannerMarketingProvider = props => {
	const [open, { setFalse, setTrue }] = useBoolean(false)

	const { data, loading: loadingPosition } = useRequest(mktService.getBannerPositions)

	console.log('============= data', data)

	const getBannerPosition = slug => {
		return find(data, i => i?.slug === slug) ?? {}
	}

	useEffect(() => {
		// const now = Date.now()
		// const bannerTime = Cookies.get('banner_show_time') ?? Date.now()
		// // console.log('============= bannerTime', now, bannerTime)
		// if (now >= bannerTime) {
		// 	setTimeout(() => {
		// 		setTrue()
		// 		Cookies.set('banner_show_time', now + 1000 * 60 * 60, {
		// 			sameSite: 'strict'
		// 		})
		// 	}, 5000)
		// }
		// if(bannerTime)
	}, [])

	const renderBannerPopup = () => {
		return (
			<CoreDialog
				open={open}
				handleClose={setFalse}
				maxWidth="sm"
				dialogContentClassName="p-0"
				dialogContent={
					<a href="https://s.eduquiz.vn/minigame-eduquiz" target="_blank" rel="noreferrer">
						<Image src="https://s.eduquiz.vn/storage/banners/01J8KNTBBF43B96Z9HQK6EEJZG.png" />
					</a>
				}
			/>
		)
	}

	const context = {
		getBannerPosition,
		loadingPosition
	}
	return (
		<CoreBannerMarketingContext.Provider value={context}>
			{props.children}
			{renderBannerPopup()}
		</CoreBannerMarketingContext.Provider>
	)
}

// CoreBannerProvider.defaultProps = {}

// CoreBannerProvider.propTypes = {}

export default React.memo(CoreBannerMarketingProvider)
