const basePath = 'workspace/exams'
export const ROUTER_WORKSPACE_EXAM = {
	root: `/${basePath}`,
	list: `/${basePath}/list`,
	edit: id => {
		return id ? `/${basePath}/edit/${id}` : `/${basePath}/edit/:id`
	},
	createWithBasic: `/${basePath}/new`,
	createWithText: `/${basePath}/create-with-text`,
	createWithAI: `/${basePath}/create-with-ai`,
	stages: `/${basePath}/stages`,
	examResult: `/${basePath}/exam-results`,
	detailExamResult: id => {
		return id ? `/${basePath}/exam-results/${id}` : `/${basePath}/exam-results/:id`
	},
	createWithQuestionPool: id =>
		id ? `/${basePath}/create-question-pool/${id}` : `/${basePath}/create-question-pool/:id`
}

export const TRANSLATE_WORKSPACE_EXAM = `workspace/exam`

export const EXAM_CREATE_TYPE = {
	question_pool: 'question_pool',
	text: 'text',
	ai_with_prompt: 'ai_with_prompt',
	ai_with_text: 'ai_with_text',
	ai_upload_file_quiz: 'ai_upload_file_quiz',
	ai_upload_file: 'ai_upload_file',
	ai_with_url: 'ai_with_url'
}

export const QUESTION_TYPE = {
	single: 'SINGLE',
	mulitple: 'MULTIPLE',
	boolean: 'BOOLEAN',
	fill_word: 'FILL_WORD',
	word_matching: 'WORD_MATCHING',
	reading: 'READING',
	essay: 'ESSAY'
}
