import planService from '@App/Studio/services/planService'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import { useRequest } from 'ahooks'
import React from 'react'
// import PropTypes from 'prop-types'

export const useFeatureRemaining = ({ feature }) => {
	const { isAuthenticated } = useCoreAuth()
	const {
		data: remaining = 0,
		runAsync: fetchRemaining,
		loading
	} = useRequest(() => planService.getFeatureRemaining(feature), {
		// defaultParams: [feature],
		ready: isAuthenticated,
		cacheKey: `handleFetchFeatureRemaining-${feature}`,
		cacheTime: 10000,
		manual: true
	})

	const canUseFeature = () => {
		return remaining > 0 || remaining === 'Y'
	}

	return { loading, remaining, canUseFeature, fetchRemaining }
}

// useFeatureRemaining.defaultProps = {}

// useFeatureRemaining.propTypes = {}
