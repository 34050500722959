import React from 'react'
import { ROUTER_EMBED } from './const'

const LazyExamDetail = React.lazy(() => import('../pages/ExamTest'))

const embedRouterConfig = [
	{
		auth: [],
		settings: {
			layout: {
				config: {
					sidebar: {
						display: false
					},
					header: {
						display: false
					},
					mobileHeader: {
						display: false
					}
				}
			}
		},
		routes: [
			{
				path: `${ROUTER_EMBED.exam}/:id`,
				exact: true,
				element: <LazyExamDetail />,
				outApp: true
			}
		]
	}
]

export default embedRouterConfig
