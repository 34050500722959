const basePath = 'embed'

export const ROUTER_EMBED = {
	exam: `/${basePath}/exam`
}

export const TRANSLATE_EMBED = {
	exam: `${basePath}/exam`
}

export const QUESTION_TYPE = {
	SINGLE: 'SINGLE',
	MULTIPLE: 'MULTIPLE',
	BOOLEAN: 'BOOLEAN'
}
