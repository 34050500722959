import { GrDocumentText } from 'react-icons/gr'
import { ROUTER_WORKSPACE_EXAM } from './const'
import { TbHomeStar } from 'react-icons/tb'
export const workspaceExamNavigationConfig = [
	// {
	// 	id: 'exam-stages',
	// 	title: 'Quản lý kỳ thi',
	// 	type: 'item',
	// 	icon: <TbHomeStar className="text-[1.2rem]" />,
	// 	link: ROUTER_WORKSPACE_EXAM.stages
	// },
	{
		id: 'exam-list',
		title: 'Đề thi',
		type: 'item',
		icon: <GrDocumentText className="text-[1.2rem]" />,
		link: ROUTER_WORKSPACE_EXAM.root
	}
]
