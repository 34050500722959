const basePath = 'order'
export const ROUTER_ORDER = {
	checkout: `/${basePath}/checkout`,
	cart: `/${basePath}/cart`
}

export const TRANSLATE_ORDER = {
	checkout: `${basePath}/checkout`,
	cart: `${basePath}/cart`
}
