import adminExamAiService from '@App/Studio/services/admin/adminExamAiService'
import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useRequest } from 'ahooks'
import React, { useEffect, useState } from 'react'
import LoadingAi from '../../components/LoadingAi'
import { Box, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TRANSLATE_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import adminExamCreateHistoryService from '@App/Studio/services/admin/adminExamCreateHistoryService'
import examService from '../../../../services/adminExamService'
import { useCorePlanFeatureContext } from '@Core/Provider/CorePlanProvider/CorePlanFeatureProvider'
import { PLAN_FEATURE } from '@Core/Components/const'
import { useFeatureRemaining } from '@Core/Provider/CorePlanProvider/hooks/useFeatureRemaining'
// import PropTypes from 'prop-types'

const CreationToolContext = React.createContext({
	loadingAI: false,
	setLoadingAI: () => {},
	saveExamCreateHistory: () => {},
	createExamWithText: data => {},
	createType: '',
	setCreateType: type => {},
	exam: {}
})

export const useCreationToolContext = () => React.useContext(CreationToolContext)

export function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	)
}

const CreationToolProvider = ({ children, ...resProps }) => {
	// const [loading,setOpen] = useS
	const { t } = useTranslation(TRANSLATE_WORKSPACE_EXAM)
	const [loadingAI, setLoadingAI] = useState(false)
	const [createType, setCreateType] = useState()
	const { featureExam } = useCorePlanFeatureContext()

	// const { handleFetchFeatureRemaining } = useCorePlanFeatureContext()

	// const { loading } = useFeatureRemaining({ feature: PLAN_FEATURE.exam })

	const { run: saveExamCreateHistory } = useRequest(adminExamCreateHistoryService.save, {
		manual: true
	})

	const { data: exam, runAsync: createExamWithText } = useRequest(examService.createExamWithText, {
		manual: true,
		onSuccess: res => {
			featureExam.fetchRemaining()
		}
	})

	// useEffect(() => {
	// 	handleFetchFeatureRemaining(PLAN_FEATURE.exam)
	// 	handleFetchFeatureRemaining(PLAN_FEATURE.question)
	// 	handleFetchFeatureRemaining(PLAN_FEATURE.create_question)
	// }, [])

	const renderLoading = () => {
		return (
			<CoreDialog
				open={loadingAI}
				// handleClose={() => setLoadingAI(false)}
				dialogContent={
					<div>
						<LoadingAi />
						<div className="my-3 text-center">
							<Typography>{t('Đang phân tích dữ liệu và tạo câu hỏi')}</Typography>
						</div>
						{loadingAI >= 5 ? <LinearProgressWithLabel value={loadingAI} /> : <LinearProgress />}
					</div>
				}
			/>
		)
	}

	const context = {
		saveExamCreateHistory,
		setLoadingAI,
		loadingAI,
		t,
		createExamWithText,
		exam,
		setCreateType,
		createType,
		...resProps
	}

	return (
		<CreationToolContext.Provider value={context}>
			{children}
			{renderLoading()}
		</CreationToolContext.Provider>
	)
}

// CreationToolProvider.defaultProps = {}

// CreationToolProvider.propTypes = {}

export default React.memo(CreationToolProvider)
