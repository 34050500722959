import { LuCrown } from 'react-icons/lu'
import { ROUTER_WORKSPACE_CLASS_ROOM } from './const'
import { Class } from '@mui/icons-material'
import { SiGoogleclassroom } from 'react-icons/si'
import { isUserStudent } from '@Core/Helpers/User'
export const workspaceClassRoomNavigationConfig = [
	{
		id: 'classroom',
		title: 'Lớp học tập',
		type: 'item',
		icon: <SiGoogleclassroom className="text-[1.2rem]" />,
		link: ROUTER_WORKSPACE_CLASS_ROOM.root
		// allowChannelType: ['WORKSPACE']
		// notCheckSubscription: true
		// basePath: basePath
		// children: [
		// 	{
		// 		id: 'plan-usage',
		// 		title: 'Gói hiện tại',
		// 		type: 'item',
		// 		link: ROUTER_WORKSPACE_PLAN.usage
		// 	},
		// 	{
		// 		id: 'plan-invoices',
		// 		title: 'Hoá đơn',
		// 		type: 'item',
		// 		link: ROUTER_WORKSPACE_PLAN.invoices
		// 	}
		// ]
	}
]
