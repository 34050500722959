import React from 'react'
import { QUIZ_ROUTER_EXAM } from './const'

const LazyExamTest = React.lazy(() => import('../pages/ExamTest'))
const LazyExamDownload = React.lazy(() => import('../pages/ExamDownload'))

const LazyExamResult = React.lazy(() => import('../pages/ExamResult'))
const LazyExamRoom = React.lazy(() => import('../pages/ExamRoom'))
const LazyExamSearch = React.lazy(() => import('../pages/ExamSearch'))
const LazyExamChannel = React.lazy(() => import('../pages/ExamChannel'))
const LazyExamRanking = React.lazy(() => import('../pages/ExamRanking'))

export const quizExamRouterConfig = {
	auth: [],
	settings: {
		layout: {
			config: {
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: `${QUIZ_ROUTER_EXAM.test}`,
			exact: true,
			element: <LazyExamTest />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.download}`,
			exact: true,
			element: <LazyExamDownload />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.result()}`,
			exact: true,
			element: <LazyExamResult />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.admin_result()}`,
			exact: true,
			element: <LazyExamResult isAdmin />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.room()}`,
			exact: true,
			element: <LazyExamRoom />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.search}`,
			exact: true,
			element: <LazyExamSearch />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.channel}`,
			exact: true,
			element: <LazyExamChannel />
		},
		{
			path: `${QUIZ_ROUTER_EXAM.ranking}`,
			exact: true,
			element: <LazyExamRanking />
		}
	]
}
