import { MdOutlineFactCheck } from 'react-icons/md'
import { ROUTER_PERSONAL_MY_RESULT } from './const'

export const personalMyResultsNavigationConfig = [
	{
		id: 'my-results',
		title: 'Kết quả thi của tôi',
		type: 'item',
		icon: <MdOutlineFactCheck className="text-[1.2rem]" />,
		link: ROUTER_PERSONAL_MY_RESULT.root
	}
]
