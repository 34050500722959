/* eslint-disable jsx-a11y/interactive-supports-focus */
import { ROUTER_PERSONAL_DASHBOARD } from '@App/Studio/Modules/Personal/Dashboard/configs/const'
import { ROUTER_WORKSPACE_EXAM } from '@App/Studio/Modules/Workspace/Exam/configs/const'
import { Typography } from '@mui/material'
import { isFunction } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const AppLogo = ({ collapsed, onClick }) => {
	const navigate = useNavigate()

	const handleClick = () => {
		if (isFunction(onClick)) {
			onClick()
		} else {
			navigate(ROUTER_PERSONAL_DASHBOARD.dashboard)
		}
	}

	return (
		<div role="button" className="flex items-center gap-2 px-3 py-2 cursor-pointer" onClick={handleClick}>
			<div className="w-[45px]">
				<img src="/assets/images/logo_128x128.png" alt="EduQuiz.io" title="EduQuiz.io" />
			</div>
			{!collapsed && (
				<Typography className="text-[1.6rem] laptop:text-[1.8rem] font-[600] mt-2 cursor-pointer" component="p">
					EduQuiz
				</Typography>
			)}
		</div>
	)
}

// AppLogo.defaultProps = {}

// AppLogo.propTypes = {}

export default React.memo(AppLogo)
