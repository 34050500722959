import { Analytics } from '@mui/icons-material'
import { FaRegUser } from 'react-icons/fa'
import { CRM_ROUTER_DASHBOARD } from './const'

const dashboardCrmNavigationConfig = {
	id: 'dashboard',
	title: 'Tổng quan',
	type: 'item',
	icon: <Analytics />,
	link: CRM_ROUTER_DASHBOARD.root
}

export default dashboardCrmNavigationConfig
