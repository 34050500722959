import { LoadingButton } from '@mui/lab'
import Icon from '@mui/material/Icon'
import { createContext, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CoreDialog from '../Dialog/CoreDialog'
import { DeleteOutline } from '@mui/icons-material'
import { MdDelete } from 'react-icons/md'
import { BiTrash } from 'react-icons/bi'
import { IconButton } from '@mui/material'

const ConfirmContext = createContext()

const useConfirm = function useConfirm() {
	const confirm = useContext(ConfirmContext)

	return confirm
}

const CoreConfirmProvider = props => {
	const { t } = useTranslation('common')
	const [config, setConfig] = useState({})
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)

	const confirm = useCallback(config => {
		setConfig(config)
		setOpen(true)
	}, [])

	const handleClose = useCallback(() => {
		config.onClose?.()
		setOpen(false)
	}, [config])

	const handleOk = useCallback(async () => {
		setLoading(true)
		try {
			await config.onOk()
			handleClose()
		} catch (error) {
			// TODO: handle error
		}
		setLoading(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config])

	const handleCancel = useCallback(async () => {
		if (!config.onCancel) {
			handleClose()
		} else {
			setLoading(true)
			try {
				await config.onCancel()
				handleClose()
			} catch (error) {
				// TODO: handle error
			}
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config])

	return (
		<>
			<ConfirmContext.Provider {...props} value={confirm} />
			<CoreDialog
				DialogProps={{
					className: 'dialog-confirm',
					sx: {
						zIndex: config?.zIndex ?? 1300
					}
				}}
				dialogAction={
					<>
						{!config.hideCancelBtn && (
							<LoadingButton color="inherit" onClick={handleCancel} variant="contained" disableElevation>
								{config.cancelText ?? t('Cancel')}
							</LoadingButton>
						)}
						<LoadingButton
							color={config.color ?? 'primary'}
							loading={loading}
							variant="contained"
							onClick={handleOk}
							disableElevation
						>
							{config.okText ?? t('Confirm')}
						</LoadingButton>
					</>
				}
				dialogTitle={config?.title}
				dialogContent={open && config?.content}
				open={open}
				// handleClose={config?.hideCancelBtn ? undefined : handleClose}
			/>
			{config?.key && (
				<button id={`btnClose-${config.key}`} type="button" onClick={handleClose} className="hidden">
					close
				</button>
			)}
		</>
	)
}

export { CoreConfirmProvider, useConfirm }
