const basePath = 'exam'
export const QUIZ_ROUTER_EXAM = {
	test: `${basePath}/test/:alias`,
	result: id => `${basePath}/result/${id ?? ':id'}`,
	admin_result: id => `${basePath}/admin/result/${id ?? ':id'}`,
	room: alias => `${basePath}/room/${alias || ':alias'}`,
	search: `${basePath}/search`,
	download: `${basePath}/download/:alias`,
	channel: `${basePath}/channel/:id`,
	ranking: `${basePath}/ranking`
}

export const QUIZ_TRANSLATE_EXAM = `common`
