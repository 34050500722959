import { CoreAutocomplete } from '@Core/Components/Input'
import { EXAM_ROOM } from '@Core/Components/const'
import { AdminPanelSettings, Public, School } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

export const useExamRoomShareStatusAccess = ({ control, name = 'status_access' }) => {
	const { t } = useTranslation()

	const {
		field: { onChange, value }
	} = useController({
		control,
		name
	})

	const statusOptions = useMemo(
		() => [
			{
				value: EXAM_ROOM.status_access.link,
				label: t('Bất kỳ ai có đường dẫn liên kết'),
				// color: 'error',
				icon: <Public color="primary" />,
				desc: t('helper_text.private')
			},
			{
				value: EXAM_ROOM.status_access.link_password,
				label: t('Bất kỳ ai có đường dẫn liên kết và mật khẩu'),
				// color: 'error',
				icon: <AdminPanelSettings color="primary" />,
				desc: t('helper_text.workspace')
			},
			{
				value: EXAM_ROOM.status_access.classroom,
				label: t('Lớp học tập và thành viên'),
				// color: 'success',
				icon: <School color="primary" />,
				desc: t('helper_text.publish')
			}
		],
		[t]
	)

	const getOptionByValue = useCallback(
		value => {
			return statusOptions.find(i => i.value === value)
		},
		[statusOptions]
	)

	const renderSelectStatus = (props = {}) => {
		return (
			<div>
				<CoreAutocomplete
					control={control}
					name={name}
					label={t('Quyền dự thi')}
					options={statusOptions}
					returnValueType="enum"
					required
					size="small"
					{...props}
					// helperText={<Typography variant="M14">{getOptionByValue()?.desc}</Typography>}
					InputProps={{
						// startAdornment: <InputAdornment position="start">{t('Gồm')}</InputAdornment>,
						startAdornment: (
							<InputAdornment position="start">
								{getOptionByValue(value)?.icon}
								{/* <AdminPanelSettings color="primary" /> */}
							</InputAdornment>
						)
					}}
				/>
				{/* <Typography variant="M14">{getOptionByValue(value)?.desc}</Typography> */}
			</div>
		)
	}

	return { renderSelectStatus, statusAccess: value, getOptionByValue }
}

// useExamRoomShareStatus.defaultProps = {}

// useExamRoomShareStatus.propTypes = {}
