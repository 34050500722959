import { setLastRouter } from '@Core/Helpers/Session'
import { findPathMatching } from '@Core/Helpers/System'
import { cloneDeep, merge, set } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useLocation, useMatch, useMatches } from 'react-router-dom'
// import PropTypes from 'prop-types'

const CoreRouterContext = React.createContext()

export const useCoreRouterContext = () => React.useContext(CoreRouterContext)

const CoreRouterProvider = ({ basePath = '', appRouter = [], children }) => {
	const location = useLocation()

	const route = useMemo(() => {
		const findRoute = appRouter.find(route => {
			let { path } = route
			if (basePath) {
				path = `${basePath}/${path}`
			}

			return findPathMatching(`${path}`, location.pathname)
		})
		// console.log('=============findRoute ', findRoute)

		const settings = cloneDeep(findRoute?.settings)

		if (findRoute?.customLayout) {
			let config = cloneDeep(settings?.layout?.config)
			config = merge(config, findRoute?.customLayout?.config)
			set(settings, 'layout.config', config)
			findRoute.settings = settings
		}
		// console.log('============= settings', settings)
		return findRoute ?? {}
	}, [location.pathname])

	useEffect(() => {
		if (basePath !== '/auth') {
			setLastRouter(location.pathname)
		}
	}, [location.pathname])

	// console.log('============= route', route)

	const context = {
		route
	}

	return <CoreRouterContext.Provider value={context}>{children}</CoreRouterContext.Provider>
}

// CoreRouterProvider.defaultProps = {}

// CoreRouterProvider.propTypes = {}

export default React.memo(CoreRouterProvider)
