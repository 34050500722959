import BaseService from '@Core/axios/BaseService'
class ClassRoom extends BaseService {
	BASE_ENDPOINT = '/lms/api/v1/admin/class-rooms'

	addStudents = data => {
		const endpoint = '/lms/api/v1/admin/class-rooms/save-students'
		return this.request.post(endpoint, data)
	}

	getExams = params => {
		const endpoint = `/quizexam/studio/api/v1/admin/classroom-exams`
		return this.request.get(endpoint, { params })
	}

	addExams = (classroom_id, data) => {
		const endpoint = `/lms/api/v1/admin/class-rooms/${classroom_id}/add-exams`
		return this.request.post(endpoint, data)
	}

	getExamsAvaiable = (classroom_id, params) => {
		const endpoint = `/lms/api/v1/admin/class-rooms/${classroom_id}/exams-available`
		return this.request.get(endpoint, { params })
	}
}

const adminClassRoomService = new ClassRoom()

export default adminClassRoomService
