import React from 'react'
import { ROUTER_WORKSPACE_SYSTEM } from './const'
import { userRole } from '@App/Studio/app-config/const'

const SettingLazy = React.lazy(() => import('../pages/Setting'))
const OnboarindLazy = React.lazy(() => import('../pages/Onboarding'))
const CreateWorkspaceLazy = React.lazy(() => import('../pages/CreateWorkspace'))

export const workspaceSystemRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_WORKSPACE_SYSTEM.onboarding,
			exact: true,
			element: <OnboarindLazy />,
			customLayout: {
				config: {
					sidebar: {
						display: false
					},
					header: {
						display: false
					}
				}
			}
		},
		{
			path: ROUTER_WORKSPACE_SYSTEM.setting,
			exact: true,
			element: <SettingLazy />
		},
		{
			path: ROUTER_WORKSPACE_SYSTEM.create_workspace,
			exact: true,
			element: <CreateWorkspaceLazy />
		}
	]
}
