import BaseService from '@Core/axios/BaseService'

class Exam extends BaseService {
	BASE_ENDPOINT = '/quizexam/studio/api/v1/admin/exams'

	constructor() {
		super()
		this.setRequest()
	}

	createExamWithText = data => {
		const endpoint = '/quizexam/studio/api/v1/admin/exams/create-with-text?telescope=1'
		return this.request.post(endpoint, data, { timeout: 60000 })
	}

	getActivityLogs = (exam_id, params) => {
		const endpoint = `/quizexam/studio/api/v1/admin/exams/${exam_id}/activity-logs`
		return this.request.get(endpoint, { params })
	}
}

const adminExamService = new Exam()

export default adminExamService
