import { Analytics, Dashboard, ShortText } from '@mui/icons-material'
import { FaRegUser } from 'react-icons/fa'
import { CRM_ROUTER_MKT } from './const'

const marketingCrmNavigationConfig = {
	id: 'mkt',
	title: 'Marketing',
	type: 'collapse',
	icon: <Dashboard />,
	// link: CRM_ROUTER_MKT.root
	children: [
		{
			id: 'shortlink',
			title: 'Rút gọn link',
			type: 'item',
			icon: <ShortText />,
			link: CRM_ROUTER_MKT.short_link
		}
	]
}

export default marketingCrmNavigationConfig
