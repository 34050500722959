import Lottie from 'lottie-react'
import React from 'react'
// import PropTypes from 'prop-types'
import animationData from '../animation-loading.json'
const LoadingAi = props => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData
	}
	return (
		<div>
			<Lottie autoPlay loop animationData={animationData} style={{ height: 300 }} />
		</div>
	)
}

// LoadingAi.defaultProps = {}

// LoadingAi.propTypes = {}

export default React.memo(LoadingAi)
