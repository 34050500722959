import { Settings } from '@mui/icons-material'

import { ROUTER_WORKSPACE_SYSTEM } from './const'

export const workspaceSystemNavigationConfig = [
	{
		id: 'setting',
		title: 'Cài đặt',
		type: 'item',
		icon: <Settings className="text-[1.2rem]" />,
		link: ROUTER_WORKSPACE_SYSTEM.setting
	}
]
