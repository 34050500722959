import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import { useBoolean } from 'ahooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExamExerciseForm from './ExamExerciseForm'
import { Button } from '@mui/material'
import { USER_INFO_TYPE } from '@Core/Components/const'
import { MdAssignmentAdd } from 'react-icons/md'
// import PropTypes from 'prop-types'

const ExamExerciseContext = React.createContext({
	handleOpenAssignExercise: () => {},
	renderButtonAssignExercise: () => {}
})

export const useExamExerciseContext = () => React.useContext(ExamExerciseContext)

const ExamExerciseProvider = ({ children, ...props }) => {
	const { t } = useTranslation()
	const { user } = useCoreAuth()
	const [open, { setFalse, setTrue }] = useBoolean(false)
	const [data, setData] = useState({ exam: null, exam_id: null })

	const handleOpenAssignExercise = data => {
		setData(data)
		setTrue()
	}

	const renderButtonAssignExercise = data => {
		if (user?.info?.type !== USER_INFO_TYPE.student) {
			return (
				<Button
					size="small"
					gradient="primary"
					variant="contained"
					startIcon={<MdAssignmentAdd />}
					onClick={() => handleOpenAssignExercise(data)}
				>
					{t('Giao bài tập')}
				</Button>
			)
		}
	}

	const renderDialogConfig = () => {
		// console.log('============= exam_id', exam_id)
		return (
			<CoreDialog
				dialogTitle={t('Cấu hình giao bài tập')}
				open={open}
				handleClose={setFalse}
				maxWidth="sm"
				// dialogContent={<ExamHomework exam_id={item.id} open={open} handleClose={handleClose} />}
				dialogContent={open && <ExamExerciseForm exam={data.exam} open={open} exam_id={data.exam_id} />}
			/>
		)
	}

	const context = {
		renderButtonAssignExercise,
		...props
	}

	return (
		<ExamExerciseContext.Provider value={context}>
			{children}
			{renderDialogConfig()}
		</ExamExerciseContext.Provider>
	)
}

// ExamExerciseProvider.defaultProps = {}

// ExamExerciseProvider.propTypes = {}

export default React.memo(ExamExerciseProvider)
