import React from 'react'
import { CRM_ROUTER_MKT } from './const'

const LazyShortLink = React.lazy(() => import('../pages/ShortLink'))

export const marketingCrmRouterConfig = {
	auth: ['admin'],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
					// collapsed: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		// {
		// 	path: ROUTER_SUBCRIPTION.plan,
		// 	element: <PlanLazy />,
		// 	exact: true
		// },
		{
			path: CRM_ROUTER_MKT.short_link,
			element: <LazyShortLink />,
			exact: true
		}
	]
}
