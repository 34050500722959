/* eslint-disable eqeqeq */
import { getQueryUrlObject } from '@Core/Helpers/Url'
import { useCoreRouterContext } from '@Core/Provider/CoreRouterProvider'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
// import PropTypes from 'prop-types'

export const useLayoutConfig = props => {
	const { route } = useCoreRouterContext()
	// const [search] = useSearchParams()

	const [openMobileSidebar, setOpenMobileSidebar] = useState(false)
	const [layoutConfig, setLayoutConfig] = useState({ sidebar: { collapsed: false, display: false } })
	const location = useLocation()
	// console.log('🚀 ~ Layout ~ layoutConfig:', layoutConfig)

	const toggleCollapse = () => {
		setLayoutConfig(prev => ({
			...prev,
			sidebar: { ...prev.sidebar, collapsed: !prev.sidebar.collapsed }
		}))
	}

	const toggleVisibleSidebar = () => {
		setLayoutConfig(prev => ({ ...prev, sidebar: { ...prev.sidebar, display: !prev.sidebar.display } }))
	}
	const toggleOpenMobileSidebar = () => {
		setOpenMobileSidebar(prev => !prev)
	}

	const routeSettings = route?.settings

	// console.log('============= route', route)
	useEffect(() => {
		const query = getQueryUrlObject()
		const config = cloneDeep(routeSettings?.layout?.config)
		if (query?.header === 'hidden') {
			config.header.display = false
		}
		// console.log('============= config', config)
		setLayoutConfig(prev => ({ ...prev, ...config }))
	}, [location.pathname])

	return {
		layoutConfig,
		toggleCollapse
	}
}

// useLayoutConfig.defaultProps = {}

// useLayoutConfig.propTypes = {}
