import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFullScreenContext } from './FullScreenProvider'
// import PropTypes from 'prop-types'

const ButtonFullScreen = props => {
	const { t } = useTranslation()

	const { handle } = useFullScreenContext()

	if (handle?.active) {
		return (
			<Tooltip title={t('Thoát chế độ toàn màn hình')}>
				<IconButton color="primary" onClick={handle.exit}>
					<FullscreenExit />
				</IconButton>
			</Tooltip>
		)
	}

	return (
		<Tooltip title={t('Chế độ toàn màn hình')}>
			<IconButton color="primary" onClick={handle.enter} id="btn-open-fullscreen">
				<Fullscreen />
			</IconButton>
		</Tooltip>
	)
}

// ButtonFullScreen.defaultProps = {}

// ButtonFullScreen.propTypes = {}

export default React.memo(ButtonFullScreen)
