import { Box, Button, Typography } from '@mui/material'
import { memo } from 'react'
import imgBanner from '@App/Studio/assets/images/banner_download.png'
import starBanner from '@App/Studio/assets/images/star_banner.png'
import mockup from '@App/Studio/assets/images/mockup_download.png'
import appstore from '@App/Studio/assets/images/appstore.png'
import ggplay from '@App/Studio/assets/images/ggplay.png'
import qr_code from '@App/Studio/assets/images/qr_code.png'
import Image from 'mui-image'
import { FaMobileScreen } from 'react-icons/fa6'
import { IoMdLaptop } from 'react-icons/io'
import { MdDownloadForOffline } from 'react-icons/md'
import { ReactSVG } from 'react-svg'
import ImageQRcode from '@Core/Components/Common/ImageQRcode'
import { androidOrIOS } from '@Core/Helpers/System'

const DownloadApp = props => {
	const devicePlatform = androidOrIOS()

	return (
		<Box>
			<div
				style={{
					backgroundImage: `url(${imgBanner})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100% 100%',
					paddingTop: '32px',
					paddingBottom: '124px'
				}}
			>
				<div className="px-6 text-center">
					<Typography className="text-white" variant="SB28">
						EduQuiz nay đã có trên nền tảng di động
					</Typography>
				</div>
				<div className="flex px-6 py-6">
					<Image src={starBanner} alt="star image beauty" />
				</div>
				<div className="px-6 text-center">
					<Typography className="text-white" variant="M16">
						Tải ngay ứng dụng EduQuiz để trải nghiệm học tập hiệu quả hơn! Bắt đầu ngay hôm nay!
					</Typography>
				</div>
			</div>
			<div className="relative flex flex-col -top-64">
				<Image src={mockup} alt="image mockup download" />
				<div className="mt-32 text-center">
					<Typography variant="M20" className="text-transparent bg-mainGradient bg-clip-text">
						Tải xuống ứng dụng
					</Typography>
					{/* <div className="flex items-center justify-center gap-3 mt-4">
						<span className="flex items-center justify-center">
							<FaMobileScreen className="text-[1.6rem]" />
						</span>
						<span className="flex items-center justify-center">
							<IoMdLaptop className="text-[1.6rem]" />
						</span>
					</div>
					<div className="mt-4">
						<Button
							startIcon={<MdDownloadForOffline className="text-[1.4rem]" />}
							variant="main-gradient"
							className="rounded-xl"
						>
							Tải ngay
						</Button>
					</div> */}
					{/* <div className="my-16">
						<Typography className="opacity-60" variant="M16">
							Hoặc
						</Typography>
					</div> */}
					<div className="flex justify-center gap-4 px-8 pt-8">
						{/* update link IOS here */}
						<a
							target="_blank"
							href="https://apps.apple.com/vn/app/eduquiz-thi-trắc-nghiệm/id6532595347?l=vi"
							rel="noreferrer"
						>
							<Image
								className="rounded-lg"
								style={{ height: '38px' }}
								src={appstore}
								alt="image appstore"
							/>
						</a>
						<a
							target="_blank"
							href="https://play.google.com/store/apps/details?id=com.ptcorp.eduquiz.vn"
							rel="noreferrer"
						>
							<Image
								className="rounded-lg"
								style={{ height: '38px' }}
								src={ggplay}
								alt="image google play"
							/>
						</a>
						{/* <Box
							sx={{
								'& svg': {
									width: '38px',
									height: '38px'
								}
							}}
							className="shrink-0"
							style={{ width: '38px', height: '38px' }}
						>
							<ImageQRcode
								url={
									devicePlatform === 'android'
										? 'https://play.google.com/store/apps/details?id=com.ptcorp.eduquiz.vn'
										: // link IOS here
										  'https://apps.apple.com/vn/app/eduquiz-thi-tr%E1%BA%AFc-nghi%E1%BB%87m/id6532595347?l=vi'
								}
							/>
						</Box> */}
					</div>
				</div>
			</div>
		</Box>
	)
}
export default memo(DownloadApp)
