import { FaRegFolder } from 'react-icons/fa'

import { ROUTER_PERSONAL_DASHBOARD } from './const'
import { Dashboard, Favorite, FavoriteBorder, History, Home } from '@mui/icons-material'

export const personalDashboardNavigationConfig = [
	{
		id: 'dashboard',
		title: 'Thư viện của tôi',
		type: 'item',
		icon: <Home />,
		link: ROUTER_PERSONAL_DASHBOARD.dashboard
	},
	{
		id: 'exam-recent',
		title: 'Truy cập gần đây',
		type: 'item',
		icon: <History />,
		link: ROUTER_PERSONAL_DASHBOARD.exams
	},
	{
		id: 'exam-favorite',
		title: 'Đề thi yêu thích',
		type: 'item',
		icon: <FavoriteBorder />,
		link: ROUTER_PERSONAL_DASHBOARD.favorite_exams
	}
]
