import React from 'react'
// import PropTypes from 'prop-types'

const PageLoader = props => {
	return (
		<div className="flex items-center justify-center w-full h-screen">
			<img
				title="eduquiz.io"
				alt="eduquiz.io"
				src="/assets/images/common/loading.svg"
				className="w-[120px] aspect-square sm:w-[140px] md:w-[180px]"
			/>
		</div>
	)
}

// PageLoader.defaultProps = {}

// PageLoader.propTypes = {}

export default React.memo(PageLoader)
