import BaseService from '@Core/axios/BaseService'
class History extends BaseService {
	BASE_ENDPOINT = '/quizexam/studio/api/v1/admin/exam-create-histories'

	constructor() {
		super()
		this.setRequest()
	}
}

const adminExamCreateHistoryService = new History()

export default adminExamCreateHistoryService
