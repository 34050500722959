import { errorMsg, handleErrorFieldBackend } from '@Core/Helpers/Message'
import Yup from '@Core/Helpers/Yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import onboardingSerivce from '../../../../@App/Auth/services/onboardingService'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'

export const useFormStepOne = props => {
	const { handleChangeStep, t } = props
	const { user } = useCoreAuth()
	const methodForm = useForm({
		mode: 'onTouched',
		defaultValues: {
			fullname: user?.info?.fullname ?? '',
			gender: user?.info?.gender ?? 'MALE',
			birthday: new Date(user?.info?.birthday) ?? '',
			mobile: user?.mobile ?? '',
			type: user?.info?.type ?? '',
			// school_id: 1,
			working_office: '',
			school_id: user?.info?.custom_data?.school_id ?? null,
			level_school_id: user?.info?.custom_data?.level_school_id ?? null
		},
		resolver: yupResolver(
			Yup.object({
				fullname: Yup.string().trim().required().min(2).max(255),
				type: Yup.string().trim().required(),
				birthday: Yup.mixed().required(),
				// school_id: Yup.mixed().required(),
				// level_school_id: Yup.mixed().required(),
				mobile: Yup.string().phone().required(),
				working_office: Yup.mixed().when('type', {
					is: val => val !== 'STUDENT',
					then: Yup.string().required()
				})
			})
		)
	})

	const onSubmit = methodForm.handleSubmit(
		async data => {
			try {
				const birthday = `${data.birthday.getFullYear()}-${
					data.birthday.getMonth() + 1
				}-${data.birthday.getDate()}`

				const payload = {
					...data,
					birthday: birthday || null,
					school_id: parseInt(data.school_id, 10)
				}

				// console.log('pay load complete: ', payload)

				await onboardingSerivce.updateStepProfile(payload)
				handleChangeStep(2)
			} catch (e) {
				errorMsg(e)
				handleErrorFieldBackend(e, methodForm.setError)
			}
		},
		e => console.log('============= e', e)
	)

	return { methodFormStepOne: methodForm, onSubmitStepOne: onSubmit }
}
