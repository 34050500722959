import Page403 from '@App/CommonPage/ErrorPage/pages/Page403'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import React from 'react'
// import PropTypes from 'prop-types'

const CrmAuthorization = ({ children }) => {
	const { user } = useCoreAuth()

	if (!user?.is_allow_crm) {
		return <Page403 />
	}
	return children
}

// CrmAuthorization.defaultProps = {}

// CrmAuthorization.propTypes = {}

export default React.memo(CrmAuthorization)
