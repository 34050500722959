import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { SESSION_STORAGE, removeDataSession } from '@Core/Helpers/Session'
import { Typography } from '@mui/material'
import Image from 'mui-image'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ROUTER_WORKSPACE_EXAM, TRANSLATE_WORKSPACE_EXAM } from '../configs/const'
import { useCorePlanContext } from '@Core/Provider/CorePlanProvider'
import { useCorePlanFeatureContext } from '@Core/Provider/CorePlanProvider/CorePlanFeatureProvider'
import AlertUpgradePlan from '@Core/Components/Common/Plan/AlertUpgradePlan'
import { PLAN_FEATURE } from '@Core/Components/const'
// import PropTypes from 'prop-types'

const CreateExamOptionContext = React.createContext({
	handleOpenCreateOption: () => {}
})

export const useCreateExamOptionContext = () => useContext(CreateExamOptionContext)

const CreateExamOptionProvider = props => {
	const [open, setOpen] = useState(false)
	const { t } = useTranslation(TRANSLATE_WORKSPACE_EXAM)
	const navigate = useNavigate()
	const { featureExam, planUpgradingMsg } = useCorePlanFeatureContext()

	const toggleOpen = () => {
		setOpen(prev => !prev)
	}

	const options = [
		// {
		// 	label: t('Bộ câu hỏi'),
		// 	link: ROUTER_WORKSPACE_EXAM.createWithQuestionPool('new'),
		// 	subText: t('Tạo đề thi nhanh từ bộ câu hỏi có sẵn'),
		// 	image: '/assets/images/exam/c_pool_question.png'
		// },

		{
			label: t('Trợ lý AI'),
			link: ROUTER_WORKSPACE_EXAM.createWithAI,
			subText: t('Tạo đề thi nhanh hơn với trợ lý AI'),
			image: '/assets/images/exam/c_ai.png'
		},
		{
			label: t('Văn bản'),
			link: ROUTER_WORKSPACE_EXAM.createWithText,
			subText: t('Tạo đề thi nhanh bằng cách soạn thảo văn bản'),
			image: '/assets/images/exam/c_text.png'
		},
		{
			label: t('Trình soạn thảo'),
			link: ROUTER_WORKSPACE_EXAM.createWithBasic,
			subText: t('Tạo đề thi từ đầu và chỉnh sửa thủ công'),
			image: '/assets/images/exam/c_editor.png'
		}

		// {
		// 	label: t('Format'),
		// 	link: '/',
		// 	subText: t('Create from existing format (TOEIC, IELTS,..)'),
		// 	image: '/assets/images/exam/c_text.png'
		// }
	]

	const renderDialog = () => {
		return (
			<CoreDialog
				open={open}
				handleClose={toggleOpen}
				maxWidth="md"
				dialogTitle={t('Tạo đề thi mới')}
				dialogContent={
					<div className="flex flex-col">
						{!featureExam.canUseFeature() && (
							<AlertUpgradePlan
								className="mb-2"
								feature={PLAN_FEATURE.exam}
								showButton
								onClick={() => setOpen(false)}
							/>
						)}
						<div className="flex gap-4 bg-[#EDF2FF] p-4">
							{options.map(item => (
								// eslint-disable-next-line jsx-a11y/no-static-element-interactions
								<div
									key={item.label}
									onClick={() => {
										if (featureExam.canUseFeature()) {
											navigate(item.link)
											toggleOpen()
											removeDataSession(SESSION_STORAGE, 'new_exam')
										} else {
											planUpgradingMsg()
											toggleOpen()
										}
									}}
									className="w-1/3 bg-white group cursor-pointer transition-all duration-300 hover:scale-[1.04] rounded-xl shadow-md p-2 flex flex-col gap-2 justify-center hover:bg-secondaryGradient "
								>
									<Typography
										component="p"
										variant="M16"
										className="text-center group-hover:text-white"
									>
										{item.label}
									</Typography>
									<Typography
										component="p"
										sx={{ minHeight: '40px' }}
										variant="M14"
										className="text-center text-[#71727D] group-hover:text-white"
									>
										{item.subText}
									</Typography>
									<div>
										<Image className="w-[50%]" src={item.image} alt="eduquiz.io" />
									</div>
								</div>
							))}
						</div>
					</div>
				}
			/>
		)
	}

	return (
		<CreateExamOptionContext.Provider value={{ handleOpenCreateOption: toggleOpen }}>
			{props.children}
			{renderDialog()}
		</CreateExamOptionContext.Provider>
	)
}

// CreateExamOptionProvider.defaultProps = {}

// CreateExamOptionProvider.propTypes = {}

export default React.memo(CreateExamOptionProvider)
