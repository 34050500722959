import { clearCache } from 'ahooks'
import { get, uniq } from 'lodash'

const cacheMasterData = {
	major: [],
	skill: [],
	subject: [],
	topic: [],
	levelSchool: [],
	school: []
}

export const addCacheMasterData = (type, key) => {
	let keys = get(cacheMasterData, type)
	if (Array.isArray(keys)) {
		keys.push(key)
		keys = uniq(keys)
		cacheMasterData[type] = keys
	}
	// console.log('============= cacheMasterData', cacheMasterData)
}

export const clearCacheMasterData = type => {
	clearCache(get(cacheMasterData, type))
}

export const clearCacheUserInfo = () => {
	clearCache('getUserInfo')
}

// export const cacheDetectResult = {}
