import { useBreakpoints } from '@Core/hooks/useBreakpoints'
import { Box, FormHelperText, InputLabel, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useController } from 'react-hook-form'

export const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiInputBase-root': {
			borderRadius: 8,
			backgroundColor: theme.palette.background.main,
			// boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;',
			border: '1px solid #A6A9C6'
		},
		'& .MuiInputBase-root fieldset': {
			borderRadius: 8,
			border: 'none'
		},
		'& .MuiInputBase-root:hover': {
			outline: '2px solid #9747FF33'
		},
		'& .MuiInputBase-root:hover fieldset': {
			border: 'none'
		},
		'& .MuiInputBase-root.Mui-focused': {
			outline: '1.5px solid #9747FF33',
			borderRadius: '8px',
			boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;'
		},
		'& .MuiInputBase-root.Mui-focused fieldset': {
			border: '2px solid #3E65FE',
			borderRadius: 6
		},
		'& .MuiInputBase-root.Mui-error': {
			outline: '1.5px solid #fc8686',
			boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;'
		},
		'& .MuiInputBase-root.Mui-error fieldset': {
			border: 'none'
		},
		'& .MuiInputBase-root.Mui-error.Mui-focused fieldset': {
			border: '2px solid #FF480E'
		}
	}
}))

const CoreInput = props => {
	const customStyle = useStyles()
	const {
		className,
		control,
		name,
		label,
		placeholder,
		InputLabelProps,
		inputProps,
		InputProps,
		required,
		readOnly,
		type,
		multiline,
		minRows,
		hidden,
		helperText,
		disabled,
		rules,
		size,
		defaultValue,
		inputClassName,
		...restProps
	} = props

	const {
		field: { value, onBlur, onChange, ref },
		fieldState: { error }
	} = useController({
		name,
		control,
		rules,
		defaultValue
	})

	let { transform } = props

	if (type === 'number') {
		transform = {
			input: value => value,
			output: e => {
				const output = e.target.value
				return output
				// console.log('============= output', Number.isNaN(output))
				// return Number.isNaN(output) ? 0 : Number(output)
			}
		}
	}

	const { mobile } = useBreakpoints()

	const responsiveSize = () => {
		if (String(size)?.trim()?.length > 0) {
			return size
		}
		return mobile ? 'small' : 'medium'
	}

	return (
		<Box className={className}>
			{label ? (
				<InputLabel
					className="text-[1rem] md:text-12 font-medium text-label "
					required={required}
					shrink
					htmlFor={name}
				>
					{label}
				</InputLabel>
			) : null}
			<TextField
				className={clsx(customStyle.root, inputClassName)}
				label={null}
				size={responsiveSize()}
				fullWidth
				type={type ?? 'text'}
				placeholder={placeholder}
				onChange={e => onChange(transform.output(e))}
				onBlur={onBlur}
				value={transform.input(value)}
				inputRef={ref}
				multiline={multiline}
				minRows={minRows}
				disabled={disabled}
				error={!!error}
				helperText={error && error.message}
				InputLabelProps={{
					shrink: false,
					style: { position: 'absolute', top: -8 },
					required,
					...InputLabelProps
				}}
				inputProps={{
					readOnly,
					...inputProps
				}}
				// eslint-disable-next-line react/jsx-no-duplicate-props
				InputProps={{
					...InputProps
				}}
				{...restProps}
			/>
			{helperText && <FormHelperText className="text-[0.8rem]">{helperText}</FormHelperText>}
		</Box>
	)
}

CoreInput.defaultProps = {
	className: null,
	label: null,
	placeholder: null,
	transform: {
		input: value => value,
		output: e => e
	},
	InputLabelProps: null,
	inputProps: null,
	InputProps: null,
	required: false,
	type: 'text',
	multiline: false,
	minRows: 5,
	disabled: false,
	size: ''
	// allowTranslation: false
}

CoreInput.propTypes = {
	className: PropTypes.string,
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	transform: PropTypes.object,
	InputLabelProps: PropTypes.object,
	inputProps: PropTypes.object,
	InputProps: PropTypes.object,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	type: PropTypes.string,
	multiline: PropTypes.bool,
	minRows: PropTypes.number,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	helperText: PropTypes.any,
	rules: PropTypes.object,
	size: PropTypes.string
	// allowTranslation: PropTypes.bool
}

export default React.memo(CoreInput)
