import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { formatTime } from '@Core/Helpers/Date'
import { Typography } from '@mui/material'
import { useBoolean } from 'ahooks'
import Image from 'mui-image'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

export const useShowExamResult = props => {
	const { t } = useTranslation()
	const [open, { setFalse, setTrue }] = useBoolean()
	const [data, setData] = useState({})
	const handleOpen = data => {
		console.log('============= data', data)
		setData(data)
		setTrue()
	}

	const renderDialogResult = () => {
		return (
			<CoreDialog
				open={open}
				handleClose={setFalse}
				dialogTitle={t('Kết quả thi')}
				dialogContent={
					<div>
						<div className="max-w-[300px] mx-auto">
							<Image
								src="/assets/images/exam/img_hoanthanh@2x.png"
								title="Exam completed"
								alt="alt"
								className="hover:scale-[1.1] aspect-video transition duration-500 object-fill"
							/>
						</div>
						<div className="flex flex-col gap-3 my-8 text-center">
							<Typography variant="SB28">
								{t('{{score}} điểm', { score: data?.total_score ?? 0 })}
							</Typography>
							<div>
								<Typography variant="M14">{t('Xếp loại')}: </Typography>
								<Typography
									className="px-3 py-2 rounded font-500"
									sx={{
										fontWeight: 400,
										backgroundColor: data?.rank_data?.background_color,
										color: data?.rank_data?.text_color
									}}
									component="span"
									variant="M12"
								>
									{data?.rank_data?.name?.toUpperCase()}
								</Typography>
							</div>
							<div className="flex justify-center w-full gap-4">
								<Typography variant="M14" className="text-neutral-700">
									{t('Số câu đúng : {{qs_true}}/{{total_qs}} câu', {
										qs_true: data?.total_question_true ?? 0,
										total_qs: data?.total_question ?? 0
									})}
								</Typography>
								<Typography variant="M14" className="text-neutral-700">
									{t('Thời gian: {{time}}', { time: formatTime(data?.total_time_used ?? 0) })}
								</Typography>
							</div>
						</div>
					</div>
				}
			/>
		)
	}

	return {
		renderDialogResult,
		handleOpen
	}
}

// useShowExamResult.defaultProps = {}

// useShowExamResult.propTypes = {}
