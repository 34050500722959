import { studioThemeCustomize } from '@App/Studio/app-config/theme'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

const ThemModeContext = createContext({
	handleToggleMode: () => {}
})

export const useQuizThemeContext = () => useContext(ThemModeContext)

const MUIThemeQuizProvider = props => {
	const [modeTheme, setModeTheme] = useState('light')
	// const theme = createTheme(studioThemeCustomize('dark'))

	const handleMode = useCallback(() => {
		setModeTheme(prev => (prev === 'light' ? 'dark' : 'light'))
	}, [])

	useEffect(() => {
		const theme = sessionStorage.getItem('quiz_theme')
		if (theme) {
			setModeTheme(theme)
		}
	}, [])

	useEffect(() => {
		sessionStorage.setItem('quiz_theme', modeTheme)
	}, [modeTheme])

	const theme = useMemo(() => createTheme(studioThemeCustomize(modeTheme)), [modeTheme])

	const context = {
		handleToggleMode: handleMode,
		modeTheme
	}

	return (
		<ThemModeContext.Provider value={context}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{props.children}
			</ThemeProvider>
		</ThemModeContext.Provider>
	)
}

export default MUIThemeQuizProvider
