import CoreRouterProvider from '@Core/Provider/CoreRouterProvider'
import React from 'react'
import Layout from './Layout'
import { quizRouterConfig } from './appConfig'
import CoreAuthProvider from '@Core/Provider/CoreAuthProvider'
import MUIThemeQuizProvider from './ThemQuizProvider'
import ExamExerciseProvider from '@Core/Components/Common/ExamExercise/ExamExerciseProvider'
import QuizExamPlanProvider from './QuizExamPlanProvider'
import FullScreenProvider from '@Core/Components/FullScreen/FullScreenProvider'
import QuizExamRoomProvider from './Modules/Exam/pages/ExamTest/QuizExamRoomProvider'
// import PropTypes from 'prop-types'

const Quiz = props => {
	// const { mobile } = useBreakpoints()
	// // if (mobile) {
	// // 	return <DownloadApp />
	// // }

	return (
		<CoreRouterProvider appRouter={quizRouterConfig} basePath="/quiz">
			<MUIThemeQuizProvider>
				<CoreAuthProvider>
					{/* <CoreOnboardingProvider skipWorkspace> */}

					<QuizExamPlanProvider>
						<QuizExamRoomProvider>
							<ExamExerciseProvider>
								<FullScreenProvider>
									<Layout />
								</FullScreenProvider>
							</ExamExerciseProvider>
						</QuizExamRoomProvider>
					</QuizExamPlanProvider>

					{/* </CoreOnboardingProvider> */}
				</CoreAuthProvider>
			</MUIThemeQuizProvider>
		</CoreRouterProvider>
	)
}

// Quiz.defaultProps = {}

// Quiz.propTypes = {}

export default React.memo(Quiz)
