import React from 'react'
import MenuTypeItem from './MenuTypeItem'
import { Typography } from '@mui/material'
// import PropTypes from 'prop-types'

const MenuTypeGroup = ({ item }) => {
	const renderChildMenu = () => {
		if (item?.children?.length > 0) {
			return item?.children?.map(_item => {
				return <MenuTypeItem item={_item} />
			})
		}
	}

	return (
		<div>
			<Typography variant="M14" color="textSecondary">
				{item?.title}
			</Typography>
			{renderChildMenu()}
		</div>
	)
}

// MenuTypeGroup.defaultProps = {}

// MenuTypeGroup.propTypes = {}

export default React.memo(MenuTypeGroup)
