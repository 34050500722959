import adminClassRoomService from '@App/Studio/services/admin/adminClassRoomService'
import { CoreAutocomplete } from '@Core/Components/Input'
import { addCacheMasterData } from '@Core/Helpers/Cache'
import { errorMsg } from '@Core/Helpers/Message'
import { useRequest } from 'ahooks'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const AdminSelectClassRoom = ({ control, query = {}, multiple, name = 'class_room_ids', required }) => {
	const { t } = useTranslation()

	const fetchData = useCallback(async () => {
		try {
			const res = await adminClassRoomService.listWithOptions({ size: 1000, ...query })
			return res?.data
		} catch (error) {
			errorMsg(error)
		}
	}, [JSON.stringify(query)])

	const cacheKey = `select-class-room-${JSON.stringify(query)}`
	const { data, loading } = useRequest(fetchData, {
		cacheKey,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000,
		refreshDeps: [JSON.stringify(query)],
		onSuccess: res => {
			addCacheMasterData('classroom', cacheKey)
		}
	})

	return (
		<CoreAutocomplete
			multiple={multiple}
			control={control}
			name={name}
			label={t('Lớp học tập')}
			options={data}
			loading={loading}
			returnValueType="enum"
			valuePath="id"
			labelPath="name"
			size="small"
			required={required}
		/>
	)
}

// SelectExamTest.defaultProps = {}

// SelectExamTest.propTypes = {}

export default React.memo(AdminSelectClassRoom)
