import React, { useState } from 'react'
import { useQuizExamRoomContext } from '../../../QuizExamRoomProvider'
import AppCard from '@Core/Components/Card/AppCard'
import { Alert, Button, Typography } from '@mui/material'
import { PlayCircle } from '@mui/icons-material'
import { useFullScreenContext } from '@Core/Components/FullScreen/FullScreenProvider'
import { useNavigate } from 'react-router-dom'
import { useBreakpoints } from '@Core/hooks'
// import PropTypes from 'prop-types'

const ExamRoomWaiting = props => {
	const { exam_room, getExamRoomConfig } = useQuizExamRoomContext()
	const [isWaiting, setIsWaiting] = useState(!!exam_room?.id)
	const { handle } = useFullScreenContext()
	const navigate = useNavigate()
	const { laptop } = useBreakpoints()
	const handleStartExam = () => {
		if (getExamRoomConfig('is_fullscreen') && laptop) {
			handle.enter()
		}
		setIsWaiting(false)
	}

	const handleBack = () => {
		navigate(`/join?room=${exam_room?.code}`)
	}
	const isFullscreen = getExamRoomConfig('is_fullscreen')
	const isAntiExitExamScreen = getExamRoomConfig('is_anti_exit_exam_screen')

	if (isWaiting) {
		return (
			<div className="max-w-sm w-full mx-auto mt-40">
				<AppCard>
					<div className="text-center mb-3">
						<Typography variant="M24">Chào mừng thí sinh tham gia phòng thi online</Typography>
					</div>
					<Typography variant="M18">Quy định tham gia thi</Typography>
					<ul className="flex flex-col gap-2 my-3 list-disc px-4">
						<Typography variant="M14" component="li">
							Thời gian làm bài thi:{' '}
							<span className="text-red font-bold">{exam_room?.exam_duration} phút</span>
						</Typography>
						<Typography variant="M14" component="li">
							Hệ thống đếm ngược thời gian làm bài, hết giờ hệ thống sẽ tự nộp bài thi.
						</Typography>
						{isFullscreen && (
							<Typography variant="M14" component="li">
								Thí sinh làm bài thi ở chế độ toàn màn hình, nếu thoát chế độ này quá{' '}
								<span className="text-red font-bold">
									{getExamRoomConfig('anti_exit_fullscreen_time')} giây
								</span>{' '}
								thì hệ thống tự động nộp bài thi.
							</Typography>
						)}

						{isAntiExitExamScreen && (
							<Typography variant="M14" component="li">
								Thí sinh làm bài thi không được phép thoát màn hình thi, nếu thoát màn hình quá{' '}
								<span className="text-red font-bold">
									{getExamRoomConfig('anti_exit_exam_screen_number')}
								</span>{' '}
								lần thì hệ thống tự nộp bài thi.
							</Typography>
						)}
					</ul>
					<Alert severity="error">
						Vui lòng không mở thêm tab trình duyệt khác ngoài tab làm bài thi, hành vi này sẽ được đánh giá
						là gian lận và có thể dẫn tới việc cấm tiếp tục thi không mong muốn
					</Alert>

					<div className="flex items-center justify-center my-5 gap-3">
						<Button
							variant="contained"
							gradient="primary"
							size="small"
							disableElevation
							startIcon={<PlayCircle />}
							onClick={handleStartExam}
						>
							Bắt đầu làm bài thi
						</Button>
						<Button variant="contained" color="error" size="small" disableElevation onClick={handleBack}>
							Trở về
						</Button>
					</div>
				</AppCard>
			</div>
		)
	}
	return props.children
}

// ExamRoomWaiting.defaultProps = {}

// ExamRoomWaiting.propTypes = {}

export default React.memo(ExamRoomWaiting)
