import { isPlanPersonalFree } from '@Core/Helpers/Plan'
import { useCorePlanContext } from '@Core/Provider/CorePlanProvider'
import clsx from 'clsx'
import React from 'react'
// import PropTypes from 'prop-types'

const CurrentPlanLabel = ({ currentPlan = {} }) => {
	// const { currentPlan } = useCorePlanContext()
	if (!currentPlan?.id) return null
	return (
		<div
			className={clsx(' px-2 py-[2px] font-500 rounded text-[10px] w-fit uppercase', {
				'text-[#7A7685] bg-gray-200': isPlanPersonalFree(currentPlan),
				'text-white bg-green': currentPlan && !isPlanPersonalFree(currentPlan)
			})}
		>
			{currentPlan?.name}
		</div>
	)
}

// CurrentPlanLabel.defaultProps = {}

// CurrentPlanLabel.propTypes = {}

export default React.memo(CurrentPlanLabel)
