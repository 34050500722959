import React from 'react'
import { useShowExamResult } from './useShowExamResult'
// import PropTypes from 'prop-types'

const ExamResultContext = React.createContext({ handleOpenExamResult: () => {} })

export const useExamResultDialogContext = () => React.useContext(ExamResultContext)

const ExamResultDialogProvider = props => {
	const { handleOpen, renderDialogResult } = useShowExamResult()
	const context = { handleOpenExamResult: handleOpen }
	return (
		<ExamResultContext.Provider value={context}>
			{props.children}
			{renderDialogResult()}
		</ExamResultContext.Provider>
	)
}

// ExamResultDialogProvider.defaultProps = {}

// ExamResultDialogProvider.propTypes = {}

export default React.memo(ExamResultDialogProvider)
