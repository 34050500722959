import CoreAuthProvider from '@Core/Provider/CoreAuthProvider'
import CoreRouterProvider from '@Core/Provider/CoreRouterProvider'
import React from 'react'

import { crmNavigationConfig, crmRouterConfig } from './appConfig'
import Layout from '@App/Studio/Layout'
import CrmAuthorization from './providers/CrmAuthorization'
// import PropTypes from 'prop-types'

const Crm = props => {
	return (
		<CoreRouterProvider appRouter={crmRouterConfig} basePath="/crm">
			<CoreAuthProvider>
				<CrmAuthorization>
					<Layout appNavigationConfig={crmNavigationConfig} />
				</CrmAuthorization>
			</CoreAuthProvider>
		</CoreRouterProvider>
	)
}

// Crm.defaultProps = {}

// Crm.propTypes = {}

export default React.memo(Crm)
