import { useBreakpoints } from '@Core/hooks'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const CoreDialog = ({
	open,
	dialogAction,
	dialogStyle,
	maxWidth = 'xs',
	PaperPropsStyle,
	dialogTitle,
	dialogTitleClassName,
	dialogCloseButtonClassName,
	dialogContent,
	dialogContentStyle,
	DialogProps,
	DialogTitleProps,
	handleClose,
	DialogContentProps,
	dialogActionClassName,
	dialogContentClassName
}) => {
	const { mobile } = useBreakpoints()
	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			PaperProps={{
				// className: mobile ? 'w-full h-auto m-0 max-h-[100vh] absolute bottom-0 rounded-t-3xl' : '',
				style: {
					// minWidth: 400,
					...PaperPropsStyle
				}
			}}
			style={dialogStyle}
			fullWidth
			maxWidth={maxWidth}
			{...DialogProps}
		>
			{dialogTitle && (
				<DialogTitle
					sx={{
						fontSize: 18
					}}
					className={clsx('text-center', dialogTitleClassName)}
					{...DialogTitleProps}
				>
					{dialogTitle}
				</DialogTitle>
			)}
			<DialogContent
				className={clsx('custom-dialog-content', dialogContentClassName)}
				{...DialogContentProps}
				style={dialogContentStyle}
			>
				{dialogContent}
			</DialogContent>
			{dialogAction && (
				<DialogActions className={clsx('shadow', dialogActionClassName)}>{dialogAction}</DialogActions>
			)}
			{handleClose && (
				<div className="absolute top-0 right-0">
					<IconButton className={clsx('', dialogCloseButtonClassName)} onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</div>
			)}
		</Dialog>
	)
}
// CoreDialog.defaultProps = {
// 	handleClose: undefined,
// 	open: false,
// 	dialogTitle: null,
// 	dialogTitleClassName: null,
// 	dialogCloseButtonClassName: null,
// 	dialogContentClassName: null,
// 	dialogContent: 'Content',
// 	dialogContentStyle: {},
// 	dialogStyle: {},
// 	PaperPropsStyle: {},
// 	DialogProps: {},
// 	DialogTitleProps: {},
// 	maxWidth: 'xs'
// }
CoreDialog.propTypes = {
	open: PropTypes.bool,
	dialogTitle: PropTypes.node,
	dialogContent: PropTypes.node,
	dialogAction: PropTypes.node,
	handleClose: PropTypes.func,
	dialogTitleClassName: PropTypes.string,
	dialogCloseButtonClassName: PropTypes.string,
	dialogContentStyle: PropTypes.object,
	dialogContentClassName: PropTypes.string,
	dialogStyle: PropTypes.object,
	PaperPropsStyle: PropTypes.object,
	DialogProps: PropTypes.object,
	DialogTitleProps: PropTypes.object,
	maxWidth: PropTypes.string,
	DialogContentProps: PropTypes.object,
	dialogActionClassName: PropTypes.string
}
export default memo(CoreDialog)
