import React from 'react'
import { ROUTER_ORDER } from './const'

const CartLazy = React.lazy(() => import('../pages/Cart'))
const CheckoutLazy = React.lazy(() => import('../pages/Checkout'))

export const orderRouterConfig = {
	auth: ['admin'],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
					// collapsed: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		// {
		// 	path: ROUTER_SUBCRIPTION.plan,
		// 	element: <PlanLazy />,
		// 	exact: true
		// },
		{
			path: ROUTER_ORDER.cart,
			element: <CartLazy />,
			exact: true,
			handle: {
				notCheckSubscription: true
			}
		},
		{
			path: `${ROUTER_ORDER.checkout}/:order_id`,
			element: <CheckoutLazy />,
			exact: true,
			handle: {
				notCheckSubscription: true
			}
		}
	]
}
