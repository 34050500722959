import { Box, CardHeader } from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import PropTypes from 'prop-types'
import { isValidElement } from 'react'
// import { Fonts } from 'shared/constants/AppEnums'

const AppCard = ({
	sxStyle,
	title,
	titleStyle,
	headerStyle,
	contentStyle,
	action,
	actionStyle,
	footer,
	footerPosition,
	footerStyle,
	children,
	...rest
}) => {
	return (
		<Card sx={{ display: 'flex', flexDirection: 'column', ...sxStyle }} {...rest}>
			{title || action ? (
				<CardHeader
					sx={{
						px: 2,
						pb: 0,
						'& .MuiCardHeader-action': {
							marginTop: 0,
							marginRight: 0
						},
						'& .MuiCardHeader-content': {
							overflow: 'hidden'
						},
						...headerStyle
					}}
					title={
						typeof title === 'object' ? (
							title
						) : (
							<Box
								component="h3"
								sx={{
									color: 'text.primary',
									fontWeight: 600,
									fontSize: 16,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
									width: '100%',
									...titleStyle
								}}
							>
								{title}
							</Box>
						)
					}
					action={
						typeof action === 'object' ? (
							action
						) : (
							<span {...actionStyle}>
								<Link
									href="#"
									color="secondary"
									underline="none"
									sx={{
										fontSize: 14,
										fontWeight: 500
									}}
								>
									{action}
								</Link>
							</span>
						)
					}
				/>
			) : null}
			<CardContent
				sx={{
					height: '100%',
					px: 2,
					'&:last-of-type': {
						pb: 1
					},
					...contentStyle
				}}
			>
				{children}
			</CardContent>
			{footer ? (
				<CardActions
					sx={{
						// px: 1,
						px: 2,
						pb: 2,
						...footerStyle
					}}
				>
					{isValidElement(footer) ? (
						footer
					) : (
						<Box component="span" sx={{ ml: footerPosition === 'right' ? 'auto' : 0 }}>
							<Link
								color="secondary"
								component="button"
								underline="none"
								sx={{
									fontSize: 14,
									fontWeight: 500
								}}
							>
								{footer}
							</Link>
						</Box>
					)}
				</CardActions>
			) : null}
		</Card>
	)
}

export default AppCard

AppCard.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	footerPosition: PropTypes.string,
	className: PropTypes.string,
	sxStyle: PropTypes.object,
	footerStyle: PropTypes.object,
	titleStyle: PropTypes.object,
	headerStyle: PropTypes.object,
	contentStyle: PropTypes.object,
	actionStyle: PropTypes.object,
	children: PropTypes.node
}

AppCard.defaultProps = {
	footerPosition: 'left'
}
