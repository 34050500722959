import React from 'react'
// import PropTypes from 'prop-types'
import { Badge, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Controller } from 'react-hook-form'
const UserTypeRadio = props => {
	const {
		className,
		control,
		name,
		legendLabel,
		options,
		labelInValue,
		row,
		required,
		classNameRadioGroup,
		helperText,
		optionContainerClass,
		...restProps
	} = props

	return (
		<div className={className}>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
					return (
						<>
							{legendLabel && (
								<FormLabel
									// component="legend"
									required={required}
									error={!!error}
									className="font-medium text-label "
								>
									{legendLabel}
								</FormLabel>
							)}
							<RadioGroup
								row={row}
								name={name}
								onChange={onChange}
								className="justify-center gap-4 md:flex-nowrap"
								value={value}
								ref={ref}
								{...restProps}
							>
								{options.map(item => {
									return (
										// eslint-disable-next-line jsx-a11y/no-static-element-interactions
										<div
											key={item?.value}
											className={clsx('flex flex-col gap-4 md:gap-2 items-center', {
												'rounded-lg shadow-md p-4 cursor-pointer': item?.mediaOption,
												'outline outline-mainBlue': item?.mediaOption && value === item.value
											})}
											onClick={() => (item?.mediaOption ? onChange(item.value) : null)}
										>
											{item?.mediaOption}
											<Badge {...item?.propsBadge} key={item.value}>
												<FormControlLabel
													value={labelInValue ? JSON.stringify(item) : item.value}
													control={<Radio color="blue" checked={item.value === value} />}
													label={item.label}
													disabled={Boolean(item?.disabled)}
												/>
											</Badge>
										</div>
									)
								})}
							</RadioGroup>
							{error && error.message && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
							{helperText && <FormHelperText>{helperText}</FormHelperText>}
						</>
					)
				}}
			/>
		</div>
	)
}

// UserTypeRadio.defaultProps = {}

// UserTypeRadio.propTypes = {}

export default React.memo(UserTypeRadio)
