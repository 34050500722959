import { MdMeetingRoom } from 'react-icons/md'
import { ROUTER_WORKSPACE_EXAM_ROOM } from './const'
import { HistoryEdu } from '@mui/icons-material'
import { isUserStudent } from '@Core/Helpers/User'

export const workspaceExamRoomNavigationConfig = [
	{
		id: 'exam_management',
		title: 'Tổ chức thi',
		type: 'item',
		icon: <HistoryEdu />,
		link: ROUTER_WORKSPACE_EXAM_ROOM.stages,
		checkHidden: () => {
			return isUserStudent()
		}
		// allowChannelType: ['WORKSPACE']
	}
]
