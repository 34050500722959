import { userRole } from '@App/Studio/app-config/const'
import { ROUTER_WORKSPACE_PLAN } from './const'
import { lazy } from 'react'

const SelectPlan = lazy(() => import('../pages/SelectPlan'))
// const Invoices = lazy(() => import('../pages/Invoices'))
const PlanPageLayoutLazy = lazy(() => import('../pages/PlanPageLayout'))

export const workspacePlanRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_WORKSPACE_PLAN.root,
			exact: true,
			element: <PlanPageLayoutLazy />,
			handle: {
				notCheckSubscription: true
			}
		},
		{
			path: ROUTER_WORKSPACE_PLAN.list_plan,
			exact: true,
			element: <SelectPlan />,
			handle: {
				notCheckSubscription: true
			}
		}
		// {
		// 	path: ROUTER_WORKSPACE_PLAN.invoices,
		// 	exact: true,
		// 	element: <Invoices />
		// }
	]
}
