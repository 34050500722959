import { useQuizThemeContext } from '@App/Quiz/ThemQuizProvider'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md'
// import PropTypes from 'prop-types'

const DarkModeButton = props => {
	const { t } = useTranslation()
	const { handleToggleMode, modeTheme } = useQuizThemeContext()
	return modeTheme === 'light' ? (
		<Tooltip title={t('Chế độ tối')}>
			<IconButton className="mr-2" color="primary" onClick={() => handleToggleMode()}>
				<MdOutlineDarkMode className="text-[1.4rem]" />
			</IconButton>
		</Tooltip>
	) : (
		<Tooltip title={t('Chế độ sáng')}>
			<IconButton className="mr-2" color="primary" onClick={() => handleToggleMode()}>
				<MdOutlineLightMode className="text-[1.4rem]" />
			</IconButton>
		</Tooltip>
	)
}

// DarkModeButton.defaultProps = {}

// DarkModeButton.propTypes = {}

export default React.memo(DarkModeButton)
