import { userRole } from '@App/Studio/app-config/const'
import { lazy } from 'react'
import { ROUTER_PERSONAL_MY_RESULT } from './const'

// const DetailExam = lazy(() => import('../pages/DetailExam'))
const ListResult = lazy(() => import('../pages/ListResult'))
// const DetailResult = lazy(() => import('../pages/DetailResult'))

export const personalMyResultsRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		// {
		// 	path: ROUTER_PERSONAL_MYRESULTS.detail_exam(),
		// 	exact: true,
		// 	element: <DetailExam />
		// },
		{
			path: ROUTER_PERSONAL_MY_RESULT.root,
			exact: true,
			element: <ListResult />
		}
	]
}
