import CoreAuthProvider from '@Core/Provider/CoreAuthProvider'
import CoreRouterProvider from '@Core/Provider/CoreRouterProvider'
import React from 'react'
import AuthLayout from './components/AuthLayout'
import { formatAppRouter } from '@Core/Helpers/System'
import { AuthRouterConfig } from './configs/AuthRouterConfig'
import { Outlet } from 'react-router-dom'
import CoreSuspense from '@Core/Components/CoreSuspense'
import { APP_NAME } from '@Core/Components/const'
// import PropTypes from 'prop-types'

export const authRouterConfig = formatAppRouter([AuthRouterConfig])

const Auth = props => {
	return (
		<CoreRouterProvider appRouter={authRouterConfig} basePath="/auth">
			<CoreAuthProvider app={APP_NAME.auth}>
				{/* <AuthLayout /> */}
				<CoreSuspense>
					<Outlet />
				</CoreSuspense>
			</CoreAuthProvider>
		</CoreRouterProvider>
	)
}

// Auth.defaultProps = {}

// Auth.propTypes = {}

export default React.memo(Auth)
