import { FaRegUser } from 'react-icons/fa'
// import { personalMyCollectionNavigationConfig } from '../MyCollection/configs/navigation'
import { personalMyResultsNavigationConfig } from '../MyResult/configs/navigation'
import { personalDashboardNavigationConfig } from '../Dashboard/configs/navigation'
import { Explore, MilitaryTech, Star, StarRate } from '@mui/icons-material'
// import { personalExamExerciseResultConfig } from '../ResultExamExercise/configs/router'
// import { ROUTER_MY_ACCOUNT } from '../../MyAccount/configs/const'
// import { AccountBalance, AccountBox } from '@mui/icons-material'

const PersonalNavigationConfig = {
	id: 'personal',
	title: 'Cá nhân',
	type: 'collapse',
	icon: <FaRegUser />,
	children: [
		...personalDashboardNavigationConfig,
		// ...personalMyCollectionNavigationConfig,
		...personalMyResultsNavigationConfig,
		// {
		// 	id: 'profile',
		// 	title: 'Thông tin tài khoản',
		// 	type: 'item',
		// 	icon: <AccountBox />,
		// 	link: ROUTER_MY_ACCOUNT.profile
		// }
		{
			id: 'explore',
			title: 'Khám phá',
			type: 'item',
			icon: <Explore />,
			link: '/quiz/exam/search'
		},
		{
			id: 'ranking',
			title: 'BXH thi đua',
			type: 'item',
			icon: <MilitaryTech />,
			link: '/quiz/exam/ranking',
			suffix: '🔥' // <img width={20} src="/assets/images/rank/ranking.png" alt="ranking" /> //
		}
	]
}

export default PersonalNavigationConfig
