import CoreDialog from '@Core/Components/Dialog/CoreDialog'
import { useBoolean } from 'ahooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContactRegisterPlanForm from './ContactRegisterPlanForm'
import ContactForm from './ContactForm'
// import PropTypes from 'prop-types'

const ContactContext = React.createContext({
	handleOpenContact: () => {},
	handleClose: () => {},
	handleRegisterPlan: plan => {}
})

export const useCoreContactContext = () => React.useContext(ContactContext)

export const CONTACT_FORM_TYPE = {
	default: 'default',
	register_trail: 'register_trail',
	register_enterprise: 'register_enterprise'
}

const CoreContactProvider = ({ children }) => {
	const { t } = useTranslation()
	const [open, { setFalse, setTrue }] = useBoolean(false)
	const [type, setType] = useState('default')
	const [plan, setPlan] = useState()
	const handleOpenContact = type => {
		setType(type)
		setTrue()
	}

	const handleRegisterPlan = plan => {
		setType('plan')
		setPlan(plan)
		setTrue()
	}

	const renderDialogContact = () => {
		const title = 'Liên hệ với chúng tôi'
		const content = <ContactForm />
		// if (type === CONTACT_FORM_TYPE.register_trail) {
		// 	title = 'Đăng ký dùng thử miễn phí'
		// 	content = <ContactRegisterPlanForm />
		// }
		// if (type === 'plan') {
		// 	title = 'Đăng ký tư vấn gói doanh nghiệp'
		// 	content = <ContactRegisterPlanForm contact_type={CONTACT_FORM_TYPE.register_enterprise} plan={plan} />
		// }

		return (
			<CoreDialog
				open={open}
				maxWidth="sm"
				handleClose={setFalse}
				dialogTitle={t(title)}
				dialogContent={open && content}
			/>
		)
	}

	const context = { handleOpenContact, handleClose: setFalse, handleRegisterPlan }
	return (
		<ContactContext.Provider value={context}>
			{children}
			{renderDialogContact()}
		</ContactContext.Provider>
	)
}

// CoreContactProvider.defaultProps = {}

// CoreContactProvider.propTypes = {}

export default React.memo(CoreContactProvider)
