import AppProvider from '@Core/Provider'
import { RouterProvider } from 'react-router-dom'
import React, { useEffect } from 'react'
import { MathJaxContext } from 'better-react-mathjax'

import { appRouter } from './config'
import Page403 from './CommonPage/ErrorPage/pages/Page403'

const App = props => {
	useEffect(() => {
		window.addEventListener('vite:preloadError', event => {
			window.location.reload() // for example, refresh the page
		})
	}, [])

	return (
		<AppProvider>
			<MathJaxContext>
				<RouterProvider router={appRouter} />
			</MathJaxContext>
		</AppProvider>
	)
}

export default App
