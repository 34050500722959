import { useBreakpoints } from '@Core/hooks'
import { Button, Typography } from '@mui/material'
import Image from 'mui-image'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserTypeRadio from './UserTypeRadio'
import { useForm } from 'react-hook-form'
import { CoreAutocomplete, CoreDatepicker, CoreInput } from '@Core/Components/Input'
import { LoadingButton } from '@mui/lab'
import SelectSchool from '@Core/Components/Common/SelectSchool'

import { useFormStepOne } from './hooks/useFormStepOne'
import SelectLevelSchool from '@Core/Components/Common/SelectLevelSchool'

// import PropTypes from 'prop-types'

const StepOne = ({ handleNext }) => {
	const [subStep, setSubStep] = useState(1)
	const { methodFormStepOne, onSubmitStepOne } = useFormStepOne({ handleChangeStep: handleNext })
	const {
		control,
		watch,
		formState: { isSubmitting, isDirty }
	} = methodFormStepOne
	const { t } = useTranslation()
	const { mobile: isMobile } = useBreakpoints()

	const genderOptions = useMemo(
		() => [
			{ label: t('Nam'), value: 'MALE' },
			{ label: t('Nữ'), value: 'FEMALE' }
		],
		[t]
	)

	const options = useMemo(
		() => [
			{
				value: 'STUDENT',
				label: t('Học sinh, sinh viên'),
				mediaOption: (
					<div className="w-full max-h-[200px]  md:aspect-square">
						<Image src={`/assets/images/auth/role_student${isMobile ? '_mb' : ''}.png`} alt="org" />
					</div>
				)
			},
			{
				value: 'TEACHER',
				label: t('Giáo viên'),
				mediaOption: (
					<div className="w-full max-h-[200px]  md:aspect-square">
						<Image src={`/assets/images/auth/role_teacher${isMobile ? '_mb' : ''}.png`} alt="org" />
					</div>
				)
			},
			{
				value: 'ORGANIZATION',
				label: t('Tổ chức, doanh nghiệp'),
				mediaOption: (
					<div className="w-full max-h-[200px]  md:aspect-square">
						<Image src={`/assets/images/auth/role_organization${isMobile ? '_mb' : ''}.png`} alt="org" />
					</div>
				)
			}
		],
		[t]
	)
	const watchType = watch('type')

	const renderStep_2 = () => {
		return (
			<div className="flex flex-col mb-12 md:mb-0">
				{/* <div className="block w-full mb-12">
					<Image src="/assets/images/auth/onboarding_mb_1.png" className="max-h-[250px]" />
				</div> */}
				<Typography variant="M18" className="my-12 text-center" component="p">
					{t('Bạn là ai?')}
				</Typography>
				<div className="my-12">
					<UserTypeRadio
						name="type"
						control={control}
						options={options}
						row
						classNameRadioGroup="grid grid-cols-1 md:grid-cols-3 gap-[1rem]"
					/>
				</div>
				<div className="sm:max-w-xs sm:mx-auto sm:w-full md:max-w-full">
					<div className="grid grid-cols-1 px-8 md:grid-cols-2 gap-formItem ">
						<CoreInput
							size="small"
							required
							control={control}
							name="fullname"
							label={t('Họ và tên')}
							placeholder={t('Nhập họ tên của bạn')}
						/>
						<CoreInput
							size="small"
							required
							control={control}
							name="mobile"
							label={t('Số điện thoại')}
							placeholder={t('Nhập số điện thoại')}
						/>
						{watchType === 'STUDENT' ? (
							<>
								<SelectLevelSchool
									size="small"
									required
									control={control}
									name="level_school_id"
									className="col-span-1 "
									label={t('Trình độ học vấn')}
									returnValueType="enum"
								/>
								<SelectSchool
									size="small"
									required
									control={control}
									name="school_id"
									label={t('Trường học')}
									className="col-span-1 "
									returnValueType="enum"
									query={{
										level_school_id: watch('level_school_id')
									}}
								/>
							</>
						) : (
							<CoreInput
								size="small"
								required
								control={control}
								name="working_office"
								label={t('Nơi công tác, làm việc')}
								placeholder={t('Nhập nơi công tác, làm việc của bạn')}
								className="col-span-1 md:col-span-2"
							/>
						)}

						<CoreAutocomplete
							size="small"
							required
							control={control}
							name="gender"
							label={t('Giới tính')}
							// placeholder={t('placeholder.gender')}
							options={genderOptions}
							returnValueType="enum"
							disableClearable
						/>
						<CoreDatepicker
							size="small"
							required
							control={control}
							name="birthday"
							label={t('Ngày sinh')}
							// placeholder={t('')}
						/>
					</div>
					<div className="flex justify-end col-span-1 gap-4 px-8 pt-8 md:col-span-2">
						{/* <Button
							onClick={() => setSubStep(1)}
							// size={isMobile ? 'small' : 'medium'}
							color="inherit"
							disableElevation
							variant="contained"
							fullWidth
							// className={clsx({ hidden: currentSubStepMobile === 1 })}
						>
							{t('Trở về')}
						</Button> */}

						<LoadingButton
							size="small"
							variant="main-gradient"
							type="submit"
							loading={isSubmitting}
							// disabled={!isDirty}
							fullWidth
							onClick={onSubmitStepOne}
						>
							{t('Xác nhận')}
						</LoadingButton>
					</div>
				</div>
			</div>
		)
	}

	// if (subStep === 1) return renderStep_1()

	return renderStep_2()
}

// StepOne.defaultProps = {}

// StepOne.propTypes = {}

export default React.memo(StepOne)
