import { LuCrown } from 'react-icons/lu'
import { ROUTER_WORKSPACE_PLAN } from './const'

export const workspacePlanNavigationConfig = [
	{
		id: 'plan',
		title: 'Gói dịch vụ',
		type: 'item',
		icon: <LuCrown className="text-[1.2rem]" />,
		link: ROUTER_WORKSPACE_PLAN.root,
		notCheckSubscription: true
		// basePath: basePath
		// children: [
		// 	{
		// 		id: 'plan-usage',
		// 		title: 'Gói hiện tại',
		// 		type: 'item',
		// 		link: ROUTER_WORKSPACE_PLAN.usage
		// 	},
		// 	{
		// 		id: 'plan-invoices',
		// 		title: 'Hoá đơn',
		// 		type: 'item',
		// 		link: ROUTER_WORKSPACE_PLAN.invoices
		// 	}
		// ]
	}
]
