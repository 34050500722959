import { userRole } from '@App/Studio/app-config/const'
import { lazy } from 'react'
import { ROUTER_PERSONAL_CLASS_ROOM } from './const'

const LazyClassRoomHome = lazy(() => import('../pages/ClassRoomHome'))
const ClassRoomListPageLazy = lazy(() => import('../pages/ClassroomListPage/index'))

export const personalClassRoomRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_PERSONAL_CLASS_ROOM.home(),
			exact: true,
			element: <LazyClassRoomHome />
		},
		{
			path: ROUTER_PERSONAL_CLASS_ROOM.root,
			exact: true,
			element: <ClassRoomListPageLazy />
		}
	]
}
