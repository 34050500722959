import BaseService from '@Core/axios/BaseService'

class Marketing extends BaseService {
	constructor(params) {
		super(params)
		this.setRequest()
	}

	getBannerPositions = () => {
		const endpoint = 'https://s.eduquiz.vn/api/v1/marketing/banner-positions?system=studio'
		return this.request.get(endpoint)
	}
}
const mktService = new Marketing()

export default mktService
