import React from 'react'
import { QUIZ_ROUTER_JOIN } from './const'

export const LazyJoinPage = React.lazy(() => import('../pages/JoinPage'))

export const quizExamRouterConfig = {
	auth: [],
	settings: {
		layout: {
			config: {
				header: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: `${QUIZ_ROUTER_JOIN.root}`,
			exact: true,
			element: <LazyJoinPage />
		}
	]
}
