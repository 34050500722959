import { personalClassRoomRouterConfig } from '../ClassRoom/configs/router'
import { personalDashboardRouterConfig } from '../Dashboard/configs/router'
// import { personalMyCollectionNavigationConfig } from '../MyCollection/configs/navigation'
import { personalMyColectionRouterConfig } from '../MyCollection/configs/router'
import { personalMyResultsRouterConfig } from '../MyResult/configs/router'
import { personalExamExerciseResultConfig } from '../ResultExamExercise/configs/router'

const PersonalRouterConfig = [
	personalDashboardRouterConfig,
	personalMyColectionRouterConfig,
	personalMyResultsRouterConfig,
	personalClassRoomRouterConfig,
	personalExamExerciseResultConfig
]

export default PersonalRouterConfig
