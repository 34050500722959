import React from 'react'
import { ROUTER_WORKSPACE_EXAM } from './const'
import { userRole } from '@App/Studio/app-config/const'
import { Navigate } from 'react-router-dom'

const CreateWithTextLazy = React.lazy(() => import('../pages/CreateWithText'))
const CreateWithAILazy = React.lazy(() => import('../pages/CreateWithAI'))

const ExamDetailLazy = React.lazy(() => import('../pages/ExamDetail'))
const ExamListLazy = React.lazy(() => import('../pages/ExamList'))
const ExamStageLazy = React.lazy(() => import('../pages/ExamStage/ListPage'))
const ExamStageDetailLazy = React.lazy(() => import('../pages/ExamStage/DetailPage'))
const ExamResultLazy = React.lazy(() => import('../pages/ExamResult/ListPage'))
const ExamResultDetailLazy = React.lazy(() => import('../pages/ExamResult/DetailPage'))

export const workspaceExamRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true,
					collapsed: false
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_WORKSPACE_EXAM.root,
			exact: true,
			element: <Navigate to={ROUTER_WORKSPACE_EXAM.list} />
		},
		{
			path: ROUTER_WORKSPACE_EXAM.stages,
			exact: true,
			element: <ExamStageLazy />
		},
		{
			path: `${ROUTER_WORKSPACE_EXAM.stages}/:id`,
			exact: true,
			element: <ExamStageDetailLazy />
		},
		{
			path: `${ROUTER_WORKSPACE_EXAM.examResult}`,
			exact: true,
			element: <ExamResultLazy />
		},
		{
			path: `${ROUTER_WORKSPACE_EXAM.detailExamResult()}`,
			exact: true,
			element: <ExamResultDetailLazy />
		},
		{
			path: ROUTER_WORKSPACE_EXAM.list,
			exact: true,
			element: <ExamListLazy />
		},
		{
			path: ROUTER_WORKSPACE_EXAM.createWithText,
			exact: true,
			element: <CreateWithTextLazy />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		},
		{
			path: ROUTER_WORKSPACE_EXAM.createWithAI,
			exact: true,
			element: <CreateWithAILazy />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		},
		{
			path: ROUTER_WORKSPACE_EXAM.createWithQuestionPool(),
			exact: true,
			element: <ExamDetailLazy />
		},
		{
			path: ROUTER_WORKSPACE_EXAM.createWithBasic,
			exact: true,
			element: <ExamDetailLazy create_type="basic" />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		},
		{
			path: ROUTER_WORKSPACE_EXAM.edit(),
			exact: true,
			element: <ExamDetailLazy />,
			customLayout: {
				config: {
					sidebar: {
						collapsed: true
					}
				}
			}
		}
	]
}
