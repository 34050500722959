import { SubcriptionRouterConfig } from './router'

const SubcriptionConfig = {
	// navigation: {
	// 	id: 'example',
	// 	title: 'Example',
	// 	type: 'group',
	// 	exact: true,
	// 	children: [...AuthNavigationConfig]
	// },
	router: [SubcriptionRouterConfig]
}

export default SubcriptionConfig
