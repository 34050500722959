import CoreSuspense from '@Core/Components/CoreSuspense'
import { Box } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'
import { Outlet, useLocation, useMatches } from 'react-router-dom'
import AppHeader from './AppHeader'
import { useLayoutConfig } from '@Core/hooks/useLayoutConfig'
import { FullScreen } from 'react-full-screen'
import { useFullScreenContext } from '@Core/Components/FullScreen/FullScreenProvider'
import { useBreakpoints } from '@Core/hooks'
import DownloadApp from '@App/CommonPage/DownloadApp'
// import PropTypes from 'prop-types'
const LayoutContext = React.createContext({
	layoutConfig: null,
	toggleCollapse: collapsed => 1,
	toggleOpenMobileSidebar: () => 1,
	openMobileSidebar: false,
	toggleVisibleSidebar: () => 1,
	examRoom: {}
})
export const useLayoutContext = () => React.useContext(LayoutContext)
const Layout = props => {
	const { layoutConfig } = useLayoutConfig()
	const { laptop } = useBreakpoints()
	const { pathname } = useLocation()
	const { handle, reportChange } = useFullScreenContext()

	if (!laptop && pathname.indexOf('quiz/exam/test') < 0) {
		return <DownloadApp />
	}
	// console.log('============= matches', matches)
	// console.log('============= layoutConfig', layoutConfig)

	return (
		// <FullScreenProvider handle={handle}>
		<FullScreen handle={handle} className="flex flex-col h-full bg-inherit" onChange={reportChange}>
			{layoutConfig?.header?.display && <AppHeader />}

			<Box
				component="section"
				className={clsx('flex  w-screen ', {
					'h-[100vh]': !layoutConfig?.header?.display,
					// 'h-[calc(100vh-60px)]': layoutConfig?.mobileHeader?.display,
					'h-[calc(100vh-60px)]': layoutConfig?.header?.display,
					'md:h-[calc(100vh-80px)]': layoutConfig?.header?.display
				})}
			>
				<Box
					id="content-wrapper"
					className={clsx('p-0 w-full h-full overflow-y-scroll scroll-smooth', {
						// 'md:px-8 md:py-4': layoutConfig?.sidebar?.display
					})}
					sx={{ backgroundColor: theme => theme.palette.background.secondary }}
				>
					{/* {props.children} */}
					<CoreSuspense>
						<Outlet />
					</CoreSuspense>
				</Box>
			</Box>
		</FullScreen>
		// </FullScreenProvider>
	)
}

// Layout.defaultProps = {}

// Layout.propTypes = {}

export default React.memo(Layout)
