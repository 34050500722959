import { userRole } from '@App/Studio/app-config/const'
import { lazy } from 'react'
import { ROUTER_PERSONAL_MYCOLLECTION, TRANSLATE_PERSONAL_MYCOLLECTION } from './const'

const MyCollection = lazy(() => import('../pages/index'))
const DetailCollection = lazy(() => import('../pages/detail/index'))

export const personalMyColectionRouterConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: true
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_PERSONAL_MYCOLLECTION.list,
			exact: true,
			element: <MyCollection />
		},
		{
			path: ROUTER_PERSONAL_MYCOLLECTION.detail(),
			exact: true,
			element: <DetailCollection />
		}
	]
}
