import { userRole } from '@App/Studio/app-config/const'
import { lazy } from 'react'
import { ROUTER_PERSONAL_RESULT_EXAM_EXERCISE } from './const'

const DetailExamExercise = lazy(() => import('../pages/DetailExamExercise'))

export const personalExamExerciseResultConfig = {
	auth: [userRole.admin],
	settings: {
		layout: {
			config: {
				sidebar: {
					display: false
				},
				header: {
					display: true
				}
			}
		}
	},
	routes: [
		{
			path: ROUTER_PERSONAL_RESULT_EXAM_EXERCISE.exam_result(),
			exact: true,
			element: <DetailExamExercise />
		}
	]
}
