import { CoreInputSearch } from '@Core/Components/Input'
import { openSearchExam } from '@Core/Helpers/Exam'
import { Button } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const SearchExamHeader = props => {
	const { t } = useTranslation()
	const { control, handleSubmit } = useForm({
		mode: 'onTouched',
		defaultValues: {
			q: ''
		}
	})

	const onSubmit = handleSubmit(async data => {
		if (data.q) {
			openSearchExam(data.q)
		}
	})

	return (
		<form onSubmit={onSubmit}>
			<CoreInputSearch name="q" control={control} placeholder={t('Tìm kiếm đề thi')} />
			<Button className="hidden" type="submit" />
		</form>
	)
}

// SearchExamHeader.defaultProps = {}

// SearchExamHeader.propTypes = {}

export default React.memo(SearchExamHeader)
