import React from 'react'
// import PropTypes from 'prop-types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import vi from 'date-fns/locale/vi'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { useController } from 'react-hook-form'
import { InputLabel } from '@mui/material'
import dayjs from 'dayjs'
import moment from 'moment'
import { parseISO } from 'date-fns'

const CoreDateTimePicker = ({
	name,
	control,
	label,
	required,
	size,
	className,
	disabled,
	readOnly,
	maxDate,
	disablePast = false,
	defaultValue = null, // dayjs(moment(new Date()).format('YYYY-MM-DD HH:mm')),
	rules
}) => {
	const {
		field: { value, onChange },
		fieldState: { error }
	} = useController({
		name,
		control
	})
	// console.log('============= value', value, dayjs(value))
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={vi}>
			<div className={className}>
				{label ? (
					<InputLabel
						className="text-[1rem] md:text-12 font-medium text-label"
						required={required}
						shrink
						htmlFor={name}
					>
						{label}
					</InputLabel>
				) : null}
				<DateTimePicker
					value={parseISO(value)}
					onChange={onChange}
					disabled={disabled}
					disablePast={disablePast}
					maxDate={maxDate}
					defaultValue={defaultValue}
					readOnly={readOnly}
					slotProps={{
						textField: {
							helperText: error && error.message,
							error: Boolean(error?.message),
							size,
							fullWidth: true
						}
					}}
					rules={rules}
				/>
			</div>
		</LocalizationProvider>
	)
}

// CoreDateTimePicker.defaultProps = {}

// CoreDateTimePicker.propTypes = {}

export default React.memo(CoreDateTimePicker)
