import { CoreCheckboxGroup } from '@Core/Components/Input'
import { LOCAL_STORAGE, getDataSession } from '@Core/Helpers/Session'
import { Button, Typography } from '@mui/material'
import Image from 'mui-image'
import React, { useMemo } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormAboutUs } from './hooks/useFormAboutUs'
import clsx from 'clsx'
import { LoadingButton } from '@mui/lab'
// import PropTypes from 'prop-types'

const AboutUs = ({ handleBack, handleNext }) => {
	const { onSubmitStepThree, methodFormStepThree } = useFormAboutUs()
	const { t } = useTranslation()
	const {
		control,
		formState: { isDirty, isSubmitting }
	} = methodFormStepThree

	return (
		<FormProvider {...methodFormStepThree}>
			<div className="">
				<div className="w-full aspect-video md:aspect-[21/6]">
					<Image src="/assets/images/auth/img_onboarding.png" alt="eduquiz.io" title="eduquiz" />
				</div>
				<form onSubmit={onSubmitStepThree} className="grid grid-cols-1 p-4 md:grid-cols-2">
					<div
						className={clsx('md:block', {
							// hidden: currentSubStepMobile === 2
						})}
					>
						<SubStepOne />
					</div>

					<div
						className={clsx('md:block', {
							// hidden: currentSubStepMobile === 1
						})}
					>
						<SubStepTwo />
					</div>
					<div className="col-span-2 gap-2 my-2 md:flex ">
						{/* <Button
							onClick={handleBack}
							size="small"
							variant="contained"
							disableElevation
							color="inherit"
							fullWidth
						>
							{t('Trở về')}
						</Button> */}
						<LoadingButton
							variant="main-gradient"
							loading={isSubmitting}
							// disabled={!isDirty}
							type="submit"
							fullWidth
						>
							{t('Xác nhận')}
						</LoadingButton>
					</div>
				</form>
			</div>
		</FormProvider>
	)
}

// AboutUs.defaultProps = {}

// AboutUs.propTypes = {}

export default React.memo(AboutUs)

export const SubStepOne = props => {
	const { control } = useFormContext()
	const { t } = useTranslation()

	const generalSetting = getDataSession(LOCAL_STORAGE, 'system_general')

	const options = useMemo(() => {
		if (generalSetting?.hear_about_us) {
			return generalSetting?.hear_about_us.map((i, index) => ({ key: i, value: i, label: i }))
		}

		return []
	}, [JSON.stringify(generalSetting?.hear_about_us)])

	return (
		<div className="flex flex-col md:h-auto">
			<Typography variant="M16">{t('Bạn biết đến EduQuiz từ đâu ?')}</Typography>
			<CoreCheckboxGroup name="hear_about_us" control={control} options={options} />
		</div>
	)
}
export const SubStepTwo = props => {
	const {
		control,
		formState: { isDirty, isSubmitting },
		handleChangeSubStepMobile
	} = useFormContext()
	const { t } = useTranslation()

	const generalSetting = getDataSession(LOCAL_STORAGE, 'system_general')
	const options = useMemo(() => {
		if (generalSetting?.use_app_for) {
			return generalSetting?.use_app_for.map((i, index) => ({ key: i, value: i, label: i }))
		}

		return []
	}, [JSON.stringify(generalSetting?.use_app_for)])

	return (
		<div className="flex flex-col">
			<Typography variant="M16">{t('Bạn sử dụng EduQuiz cho mục đích nào ?')}</Typography>
			<CoreCheckboxGroup name="use_app_for" control={control} options={options} />
		</div>
	)
}
