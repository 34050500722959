import React, { Suspense } from 'react'
import PageLoader from './Common/PageLoader'
// import PropTypes from 'prop-types'

const CoreSuspense = props => {
	return <Suspense fallback={<PageLoader />}>{props.children}</Suspense>
}

// CoreSuspense.defaultProps = {}

// CoreSuspense.propTypes = {}

export default React.memo(CoreSuspense)
