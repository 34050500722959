import ReactDOM from 'react-dom/client'
import './styles/app_base.css'
import './styles/tailwind_css.css'

import App from '@App'
import 'i18n'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorker from './serviceWorker'

import $ from 'jquery'
import katex from 'katex'
import 'katex/dist/katex.min.css'
import 'mathquill/build/mathquill.css'
import 'mathquill4quill/mathquill4quill.css'
import * as Sentry from '@sentry/react'

window.katex = katex
// eslint-disable-next-line no-multi-assign
window.jQuery = window.$ = $

// Sentry.init({
// 	dsn: 'https://ba4497bbf26060e04c451d66d663fc11@o4507929698566144.ingest.us.sentry.io/4507929702301696',
// 	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
// 	// Tracing
// 	tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: ['localhost', /^https:\/\/eduquiz\.vn\/api/],
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// })

ReactDOM.createRoot(document.getElementById('root')).render(
	// <React.StrictMode>
	<App />
	// </React.StrictMode>
)

reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
