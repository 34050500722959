import { CoreAutocomplete, CoreInput } from '@Core/Components/Input'
import { LoadingButton } from '@mui/lab'
import { Alert, Button, Divider, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCoreAuth } from '../CoreAuthProvider'
import { Facebook, Mail, Phone } from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import Yup from '@Core/Helpers/Yup'
import { errorMsg } from '@Core/Helpers/Message'
import workspaceService from '@App/Studio/services/workspaceService'
import { useCoreWorkspaceContext } from '../CoreWorkspaceProvider'
// import PropTypes from 'prop-types'

const ContactRegisterPlanForm = ({ contact_type, plan }) => {
	const { t } = useTranslation()
	const { user } = useCoreAuth()
	const { currentWorkspace } = useCoreWorkspaceContext()
	const [isSent, setIsSent] = useState(false)
	const {
		control,
		formState: { isDirty, isSubmitting },
		handleSubmit
	} = useForm({
		mode: 'onTouched',
		defaultValues: {
			user_id: user?.id,
			channel_id: currentWorkspace?.id,
			company: '',
			company_size: '',
			company_major: '',
			fullname: user?.info?.fullname ?? '',
			mobile: user?.mobile ?? '',
			email: user?.email ?? '',
			message: plan?.id && plan?.slug !== 'workspace_free' ? '' : 'Đăng ký dùng thử',
			contact_type,
			plan_id: plan?.id
		},
		resolver: yupResolver(
			Yup.object({
				company: Yup.string().required(),
				company_size: Yup.string().required(),
				company_major: Yup.string().required(),
				fullname: Yup.string().required(),
				mobile: Yup.string().required(),
				email: Yup.string().required(),
				message: Yup.string().required()
			})
		)
	})
	// console.log('============= isSubmitSuccessful', isSubmitSuccessful)

	const majorOptions = useMemo(() => {
		const options = [
			'Giáo dục',
			'Y tế',
			'Tài chính',
			'Bất động sản',
			'Bán lẻ',
			'Công nghệ thông tin',
			'Dịch vụ',
			'Sản xuất',
			'Nông nghiệp',
			'Xây dựng',
			'Khác'
		].map(i => ({ value: i, label: t(i) }))
		return options
	}, [t])

	const onSubmit = handleSubmit(async data => {
		try {
			await workspaceService.sendWorkspaceContact(data)
			setIsSent(true)
		} catch (e) {
			errorMsg(e)
		}
	})

	if (isSent) {
		return (
			<div>
				<Alert variant="filled">{t('Gửi yêu cầu đăng ký gói dịch vụ thành công')}</Alert>
				<div className="flex flex-col gap-4 my-4">
					<Typography variant="M16">
						{t(
							'Cảm ơn bạn đã quan tâm và sử dụng ứng dụng. Chúng tôi sẽ liên hệ với bạn để xác nhận thông tin và tư vấn hỗ trợ về gói dịch vụ.'
						)}
					</Typography>
					<Divider>{t('Hoặc')}</Divider>
					<Typography variant="M16">{t('Bạn có thể liên hệ với chúng tôi bằng cách:')}</Typography>
					<div className="flex gap-5">
						<Button color="success" startIcon={<Phone />}>
							<a href="tel:0394149966">0394149966</a>
						</Button>
						<Button color="error" startIcon={<Mail />}>
							<a href="mailto:cskh@eduquiz.vn">cskh@eduquiz.vn</a>
						</Button>
						<Button startIcon={<Facebook />}>
							<a href="https://www.facebook.com/eduquiz.vn" target="_blank" rel="noreferrer">
								Fanpage
							</a>
						</Button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div>
			<Alert>
				{t(
					'Chúng tôi đang giúp cho 1000+ khách hàng và đối tác tổ chức các kỳ thi đánh giá năng lực nâng cao chất lượng đào tạo nhân sự, học viên '
				)}
			</Alert>
			<form onSubmit={onSubmit} className="flex flex-col mt-3 gap-formItem">
				<CoreInput
					control={control}
					name="company"
					label={t('Tên tổ chức, doanh nghiệp')}
					size="small"
					placeholder={t('Nhập tên tổ chức, doanh nghiệp của bạn')}
					required
				/>
				<CoreInput
					control={control}
					name="company_size"
					label={t('Quy mô nhân sự, học viên')}
					size="small"
					placeholder={t('Nhập quy mô nhân sự của tổ chức, doanh nghiệp')}
					required
					type="number"
				/>
				<CoreAutocomplete
					control={control}
					name="company_major"
					label={t('Lĩnh vực kinh doanh')}
					size="small"
					required
					options={majorOptions}
					returnValueType="enum"
				/>
				<CoreInput
					control={control}
					name="fullname"
					label={t('Tên của bạn')}
					size="small"
					placeholder={t('Nhập họ tên của bạn')}
					required
				/>
				<CoreInput
					control={control}
					name="email"
					label={t('Email của bạn')}
					size="small"
					placeholder={t('Nhập email của bạn')}
					required
				/>
				<CoreInput
					size="small"
					required
					control={control}
					name="mobile"
					label={t('Số điện thoại')}
					placeholder={t('Nhập số điện thoại')}
				/>
				{plan?.id && plan.slug !== 'workspace_free' && (
					<CoreInput
						multiline
						control={control}
						name="message"
						label={t('Mong muốn tư vấn')}
						size="small"
						placeholder={t('Nhập các yêu cầu mong muốn tư vấn gói dịch vụ')}
						required
					/>
				)}

				<div>
					<LoadingButton
						type="submit"
						variant="contained"
						gradient="primary"
						fullWidth
						loading={isSubmitting}
					>
						{t('Xác nhận đăng ký')}
					</LoadingButton>
				</div>
			</form>
		</div>
	)
}

// ContactRegisterPlanForm.defaultProps = {}

// ContactRegisterPlanForm.propTypes = {}

export default React.memo(ContactRegisterPlanForm)
