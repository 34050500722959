import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { useFullScreenHandle } from 'react-full-screen'
import { useConfirm } from '../Confirm/CoreConfirm'
import { useQuizExamRoomContext } from '@App/Quiz/Modules/Exam/pages/ExamTest/QuizExamRoomProvider'
import { Alert } from '@mui/material'
import { get } from 'lodash'
import quizExamService from '@App/Quiz/services/quizExamService'
import { EXAM_ROOM_LOG } from '../const'
import { useCountDown } from 'ahooks'

const FullScreenContext = React.createContext({
	handle: {},
	setDisableCheckExitFullscreen: () => {}
})

export const useFullScreenContext = () => React.useContext(FullScreenContext)

const FullScreenProvider = props => {
	const handle = useFullScreenHandle()

	const { exam_room, examResult, getExamRoomConfig, checkExitExamScreen, isExamRoomCompleted, disableCheckAnti } =
		useQuizExamRoomContext()

	const confirm = useConfirm()

	const handleConfirmFullscreen = () => {
		confirm({
			title: 'Xác nhận thi ở chế độ toàn màn hình',
			onOk: async () => {
				return handle.enter()
			}
		})
	}

	const handleAlertExitFullscreen = () => {
		if (checkExitExamScreen() || isExamRoomCompleted) {
			return false
		}
		const anti_exit_fullscreen_time = getExamRoomConfig('anti_exit_fullscreen_time')
		confirm({
			key: 'alertExitFullscreen',
			title: 'Cảnh báo hành vi gian lận',
			content: <AlertCountDown second={parseInt(anti_exit_fullscreen_time, 10)} />,
			onOk: async () => {
				quizExamService.saveLog({
					exam_room_id: exam_room.id,
					exam_result_id: examResult?.id,
					candidate_id: examResult.user_id,
					type: EXAM_ROOM_LOG.type.RETURN_FULLSCREEN
				})
				return handle.enter()
			},
			okText: 'Trở lại màn hình thi',
			hideCancelBtn: true
		})
	}

	const reportChange = (state, handle) => {
		if (checkExitExamScreen() || disableCheckAnti) {
			return false
		}

		if (exam_room?.id && examResult?.id) {
			if (getExamRoomConfig('is_fullscreen')) {
				quizExamService.saveLog({
					exam_room_id: exam_room.id,
					exam_result_id: examResult?.id,
					candidate_id: examResult.user_id,
					type: state ? EXAM_ROOM_LOG.type.RETURN_FULLSCREEN : EXAM_ROOM_LOG.type.EXIT_FULSCREEN
				})
				handleAlertExitFullscreen()
				// setTargetDate(Date.now() + 60000)
			}
		}
	}

	const context = {
		handle,
		reportChange,
		handleConfirmFullscreen,
		...props
	}

	return <FullScreenContext.Provider value={context}>{props.children}</FullScreenContext.Provider>
}

// FullScreenProvider.defaultProps = {}

// FullScreenProvider.propTypes = {}

const AlertCountDown = ({ second = 60 }) => {
	const [targetDate, setTargetDate] = useState()
	const [countdown] = useCountDown({
		targetDate,
		onEnd: () => {
			// alert('End of the time')
			document.getElementById('btnClickSubmitExamTest').click()
			document.getElementById('btnClose-alertExitFullscreen').click()
		}
	})

	useEffect(() => {
		setTargetDate(Date.now() + second * 1000)
	}, [])

	return (
		<Alert severity="error" variant="filled">
			Hành động này được quy định là gian lận, hệ thống sẽ tư động nộp bài sau{' '}
			<span className="font-bold">{`${Math.round(countdown / 1000)}s`}</span> nếu không quay trở lại chế độ toàn
			màn hình
		</Alert>
	)
}

export default React.memo(FullScreenProvider)
