import { successMsg } from '@Core/Helpers/Message'
import { ContentCopy, CopyAll } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import PropTypes from 'prop-types'

const ButtonCopy = ({ url, buttonTitle }) => {
	const { t } = useTranslation()
	const [isCopy, setIsCopy] = useState(false)
	const handleClick = () => {
		navigator.clipboard.writeText(url)
		successMsg(t('Đã sao chép liên kết'))
		setIsCopy(true)
	}
	return (
		<Tooltip title={t('Sao chép liên kết')}>
			<Button
				variant="contained"
				gradient="primary"
				disableElevation
				size="small"
				startIcon={<ContentCopy />}
				onClick={handleClick}
				// disabled={isCopy}
			>
				{t(buttonTitle ?? 'Sao chép')}
			</Button>
		</Tooltip>
	)
}

// ButtonCopy.defaultProps = {}

// ButtonCopy.propTypes = {}

export default React.memo(ButtonCopy)
