import BaseService from '@Core/axios/BaseService'

class Join extends BaseService {
	BASE_ENDPOINT = '/workspace/studio/api/v1/admin/exam-stages'

	constructor() {
		super()
		this.setRequest()
	}

	checkClassRoom = code => {
		const endpoint = `/lms/api/v1/student/class-rooms/check/${code}`
		return this.request.get(endpoint)
	}

	joinClass = data => {
		const endpoint = `/lms/api/v1/student/class-rooms/join`
		return this.request.post(endpoint, data)
	}

	checkExamRoom = alias => {
		//
		const endpoint = `/examroom/studio/api/v1/user/exam-rooms/${alias}`
		return this.request.get(endpoint)
	}
}

const joinService = new Join()

export default joinService
