import { schoolService } from '@App/Studio/services/schoolService'
import { errorMsg } from '@Core/Helpers/Message'
import React, { useCallback } from 'react'
import { CoreAutocomplete } from '../Input'
import { useRequest } from 'ahooks'
import { addCacheMasterData } from '@Core/Helpers/Cache'
// import PropTypes from 'prop-types'

const SelectSchool = props => {
	const { control, name, query, ...restProps } = props

	const fetchSchools = useCallback(async () => {
		try {
			const res = await schoolService.listWithOptions({ size: 1000, ...query })
			return res.data
		} catch (error) {
			errorMsg(error)
		}
	}, [JSON.stringify(query?.level_school_ids)])

	const { data, loading } = useRequest(fetchSchools, {
		cacheKey: `select-school`,
		cacheTime: 60 * 60 * 1000,
		staleTime: 60 * 60 * 1000,
		onSuccess: res => {
			addCacheMasterData('school', 'select-school')
		}
		// refreshDeps: [JSON.stringify(query)]
	})

	return (
		<CoreAutocomplete
			control={control}
			name={name}
			// fetchOptions={fetchSchools}
			options={data}
			loading={loading}
			valuePath="id"
			labelPath="name"
			{...restProps}
		/>
	)
}

// SelectSchool.defaultProps = {}

// SelectSchool.propTypes = {}

export default React.memo(SelectSchool)
