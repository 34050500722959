import { formatAppRouter } from '@Core/Helpers/System'
import embedConfig from './Modules/Embed/configs'
import MyAccountConfig from './Modules/MyAccount/configs'
import { orderRouterConfig } from './Modules/Order/configs/router'

import SubcriptionConfig from './Modules/Subcription/configs'
import WorkspaceConfig from './Modules/Workspace/configs'
import PersonalConfig from './Modules/Personal/configs'
import { FcOnlineSupport } from 'react-icons/fc'
export const appNavigationConfig = [...PersonalConfig.navigation, ...WorkspaceConfig.navigation]

export const studioRouterConfig = formatAppRouter([
	// ...AuthConfig.router,
	...embedConfig.router,
	...SubcriptionConfig.router,
	...WorkspaceConfig.router,
	...MyAccountConfig.router,
	...PersonalConfig.router,
	orderRouterConfig
])

export const footerNavigationConfig = [
	// {
	// 	id: 'suggest-feature',
	// 	title: 'Gợi ý tính năng & báo lỗi',
	// 	type: 'action',
	// 	icon: <FeaturedPlayList fontSize="small" color="secondary" />,
	// 	onClick: ({ handleOpenContact }) => {
	// 		window.open('https://eduquiz.features.vote/board', '_blank')
	// 	}
	// },
	{
		id: 'contact-us',
		title: 'Hỗ trợ tư vấn',
		type: 'action',
		icon: <FcOnlineSupport className="text-[1.2rem]" />,
		onClick: ({ handleOpenContact }) => {
			handleOpenContact()
		}
	}
]
