import planService from '@App/Studio/services/planService'
import PageLoader from '@Core/Components/Common/PageLoader'
import { useRequest, useUpdateEffect } from 'ahooks'
import React, { useEffect, useState } from 'react'
import { useCoreWorkspaceContext } from '../CoreWorkspaceProvider'
import { isPlanPersonalFree } from '@Core/Helpers/Plan'
import { Typography } from '@mui/material'
import { useLocation, useMatches, useNavigate } from 'react-router-dom'
import { ROUTER_WORKSPACE_PLAN } from '@App/Studio/Modules/Workspace/Plan/configs/const'
import { ROUTER_ORDER } from '@App/Studio/Modules/Order/configs/const'
import { hideLoadingPage, showLoadingPage } from '@Core/Helpers/System'
import { errorMsg } from '@Core/Helpers/Message'
import { useTranslation } from 'react-i18next'
import CorePlanFeatureProvider from './CorePlanFeatureProvider'
// import PropTypes from 'prop-types'

const CorePlanContext = React.createContext({
	currentPlan: {},
	currentSubscription: {},
	checkHasActivePlan: () => {},
	plans: [],
	loadingPlans: true,
	activePlanFree: () => {}
})

export const useCorePlanContext = () => React.useContext(CorePlanContext)

const CorePlanProvider = ({ children, ...resProps }) => {
	const { t } = useTranslation()
	const location = useLocation()
	const { currentWorkspace, onboardingCompleted } = useCoreWorkspaceContext()
	const matches = useMatches()
	// console.log('============= matches', matches)
	const [loadingPlan, setLoadingPlan] = useState(Boolean(currentWorkspace?.id))
	const [currentPlan, setCurrentPlan] = useState({})
	const navigate = useNavigate()
	let locations
	const locationSesstion = sessionStorage.getItem('location_join_class')
	if (locationSesstion) {
		locations = JSON.parse(locationSesstion)
	}

	const {
		data: plans,
		run: getPublicPlans,
		loading: loadingPlans
	} = useRequest(planService.list, {
		ready: currentWorkspace?.id,
		cacheKey: 'getPublicPlans',
		staleTime: 30 * 60 * 1000,
		cacheTime: 30 * 60 * 1000
		// manual : true
	})

	const {
		data: currentSubscription,
		runAsync: getCurrentPlanSubscription
		// loading = true
	} = useRequest(planService.getCurrentPlanSubscription, {
		manual: true,
		onSuccess: res => {
			setCurrentPlan(res?.plan)
		},
		onFinally: res => {
			setLoadingPlan(false)
		}
	})

	const { data, run: activePlanFree } = useRequest(planService.activePlanFree, {
		manual: true,
		onBefore: () => {
			showLoadingPage()
		},
		onSuccess: res => {
			getCurrentPlanSubscription().then(() => {
				if (locations) {
					navigate(locations)
					sessionStorage.removeItem('location_join_class')
				} else {
					navigate('/')
				}
				hideLoadingPage()
			})
		},
		onError: e => {
			errorMsg(t('Có lỗi xảy ra, vui lòng thử lại'))
			hideLoadingPage()
		}
		// onFinally: () => {
		// 	hideLoadingPage()
		// }
	})

	// console.log('============= currentPlan', currentPlan)

	const renderCurrentPlan = () => {
		if (isPlanPersonalFree(currentPlan)) {
			return <div className="text-back bg-gray-100 px-2 py-[4px] font-700 rounded">Free</div>
		}
	}

	useEffect(() => {
		if (currentWorkspace?.id) {
			// getPublicPlans()
			getCurrentPlanSubscription()
		}
	}, [currentWorkspace])

	// console.log('============= location', location)
	// useUpdateEffect(() => {
	// 	// console.log('============= currentWorkspace', currentWorkspace)
	// 	if (currentWorkspace?.id) {
	// 		const hasNotCheckSubcription = matches.filter(i => i.handle?.notCheckSubscription === true).length > 0
	// 		if (!hasNotCheckSubcription && onboardingCompleted) {
	// 			if (currentWorkspace && !loadingPlan && !currentPlan?.id) {
	// 				navigate(ROUTER_WORKSPACE_PLAN.list_plan, { state: { location }, replace: true })
	// 			}
	// 		}
	// 	}
	// }, [location.pathname, loadingPlan, currentPlan, currentWorkspace, onboardingCompleted])

	const checkHasActivePlan = slug => {
		return currentPlan?.slug === slug
	}

	const context = {
		currentSubscription,
		plans,
		loadingPlans,
		currentPlan,
		getCurrentPlanSubscription,
		renderCurrentPlan,
		checkHasActivePlan,
		activePlanFree,
		...resProps
	}

	if (loadingPlan) return <PageLoader />

	return (
		<CorePlanContext.Provider value={context}>
			<CorePlanFeatureProvider>{children}</CorePlanFeatureProvider>
		</CorePlanContext.Provider>
	)
}

// CorePlanProvider.defaultProps = {}

// CorePlanProvider.propTypes = {}

export default React.memo(CorePlanProvider)
