import Page403 from '@App/CommonPage/ErrorPage/pages/Page403'
import { ROUTER_AUTH } from '@App/Auth/configs/const'
import { userRole } from '@App/Studio/app-config/const'
import { SESSION_STORAGE, setDataSession } from '@Core/Helpers/Session'
import { hasPermission } from '@Core/Helpers/User'
import { useCoreAuth } from '@Core/Provider/CoreAuthProvider'
import { useCoreRouterContext } from '@Core/Provider/CoreRouterProvider'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import PropTypes from 'prop-types'

const CoreAuthorization = props => {
	const { user, isAuthenticated } = useCoreAuth()
	const { route } = useCoreRouterContext()
	const location = useLocation()
	const navigate = useNavigate()
	const [hasPermisison, setHasPermission] = useState(true)
	// console.log('============= auth', user, auth, route)
	// console.log('============= route', route)
	// TODO: check role user and return page
	const checkRolesUser = () => {
		return true
	}

	useEffect(() => {
		// console.log('============= route.auth', route.auth, user?.role)
		const isPassAuth = hasPermission(route.auth, user?.role ?? [userRole.guest])
		// console.log('============= isPassAuth', isPassAuth)

		if (!isPassAuth) {
			const { pathname } = location

			if (!user?.role || user?.role.length === 0) {
				setDataSession(SESSION_STORAGE, 'last_router', pathname)
				navigate(ROUTER_AUTH.login, {
					state: { redirectUrl: pathname }
				})
			} else if (route.auth.includes(userRole.guest)) {
				let locations
				const locationSesstion = sessionStorage.getItem('location_join_class')
				if (locationSesstion) {
					locations = JSON.parse(locationSesstion)
				}

				if (locations) {
					navigate(locations)
				} else {
					navigate('/')
				}
			} else {
				setHasPermission(false)
			}
		} else {
			setHasPermission(true)
		}
	}, [location.pathname, isAuthenticated])

	if (!hasPermisison) {
		return <Page403 />
	}

	return props.children
}

// CoreAuthorization.defaultProps = {}

// CoreAuthorization.propTypes = {}

export default React.memo(CoreAuthorization)
